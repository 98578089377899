import axiosInstance from './axiosConfig';

const ApiService = {
    isAuthenticated: (): boolean => {
        const token = localStorage.getItem("sessionToken");
        // Check if the token exists and is valid
        if (token) {
            // Here you can add more checks for token validity if needed
            console.log("token: " + token)
            const payload = JSON.parse(atob(token.split('.')[1])); // Decode the payload of the JWT
            const now = Date.now().valueOf() / 1000;
            // Check if the token is expired
            if (payload.exp < now) {
                console.log("Token has expired.");
                return false;
            }

            return true;
        } else {
            return false;
        }
    },
    requestOTP: async (email: string): Promise<JSON> => {
        try {
            const response = await axiosInstance.post('/api/otp/request', {
                headers: {
                    "Content-Type": "application/json",
                },
                email: email
            });
            return JSON.parse(response.data.body);
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
            } else {
                console.error("Error requesting OTP:", error);
            }
            throw error;
        }
    },
    verifyOTP: async (email: string, otp: string): Promise<{ success: boolean }> => {
        try {
            const response = await axiosInstance.post('/api/otp/verify', {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("sessionToken")}`
                },
                email: email,
                otp: otp
            });

            const body = JSON.parse(response.data.body);
            localStorage.setItem("sessionToken", body.session_token); // Example storage

            return {success: true}
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
            } else {
                console.error("OTP verification failed", error);
            }
            throw error;
        }
    },
    fetchProfile: async (): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            const response = await axiosInstance.get('/api/otp/profile', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("sessionToken")}`
                    },
                    params: {
                        // domain: configJson.domain
                    }
                }
            )
            return JSON.parse(response.data.body)
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    updateProfile: async (profile_data: JSON): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)
            const response = await axiosInstance.post('/api/otp/profile', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    json: profile_data
                }
            )
            return JSON.parse(response.data.body)
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    createCheckoutSession: async (product_id: String): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            const response = await axiosInstance.post('/api/payment/create-checkout-session/' + product_id, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    createSubscriptionCheckout: async (price_id: String): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            const response = await axiosInstance.post('/api/payment/create-subscription-checkout/' + price_id, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    modifyPaymentMethod: async (modifyDefaultMethod: boolean, modifySubscriptionMethod: boolean): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            console.log(modifyDefaultMethod + " : " + modifySubscriptionMethod)

            const response = await axiosInstance.post('/api/payment/modify-payment-method?default=' + modifyDefaultMethod + "&subscription=" + modifySubscriptionMethod, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    upgradeSubscription: async (price_id: String): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            const response = await axiosInstance.post('/api/payment/upgrade-subscription/' + price_id, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    downgradeSubscription: async (price_id: String): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            const response = await axiosInstance.post('/api/payment/downgrade-subscription/' + price_id, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    revertPendingSubscriptionChange: async (): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            const response = await axiosInstance.post('/api/payment/revert-pending-subscription-changes', {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    getCheckoutStatus: async (sessionId: String | null): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)
            const response = await axiosInstance.get('/api/payment/session-status?session-id=' + sessionId, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    getSubscriptionStatus: async (): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)
            const response = await axiosInstance.get('/api/payment/subscription-status', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    getSubscriptionUpgradeCost: async (price_id: string): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)
            const response = await axiosInstance.get('/api/payment/preview-upgrade-subscription/' + price_id, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    getCreditPackages: async (): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)
            const response = await axiosInstance.get('/api/payment/products/credits', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    cancelSubscription: async (): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            const response = await axiosInstance.post('/api/payment/cancel-subscription', {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    getPaymentInfo: async (): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")

            await axiosInstance.get('/api/otp/validate/' + token);

            console.log("Token: " + token)

            const response = await axiosInstance.get('/api/payment/payment-info', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            //console.log(response.data.body)
            return response.data.body
            // setData((preData) => ({...preData, profile: newProfile}));

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    createAsset: async (formData: FormData): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            let headers: {[key: string]: string} = {
                "Content-Type": "application/json"
            }

            if (localStorage.getItem("sessionToken")) {
                headers['Authorization'] = `Bearer ${token}`
            }

            const response = await axiosInstance.postForm('/api/asset/create', formData, { headers }); // Note: No Content-Type header
            console.log(response.data);
            const body = JSON.parse(response.data.body); // Assuming the response comes in a format that needs parsing
            console.log(JSON.stringify(body))
            const {assetId, fileName} = body;
            console.log('assetId: ' + assetId)
            console.log('fileName: ' + fileName)
            return {assetId, fileName};
        } catch (error:any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching assets:", error);
            }
            throw error;
        }
    },
    logout: (): void => {
        localStorage.removeItem('authToken');
    },
    fetchTasks: async (setTasks: any): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            const response = await axiosInstance.get('/api/tasks/all', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("sessionToken")}`
                },
                params: {
                   //  domain: configJson.domain
                }
            });

            const newTasks = JSON.parse(response.data.body)
            setTasks(newTasks);

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
    linkedinAuth: async (code: any): Promise<any> => {
        try {
            const token = localStorage.getItem("sessionToken")
            await axiosInstance.get('/api/otp/validate/' + token);

            const response = await axiosInstance.post('api/linkedin/linkedin-auth',
                {
                    code,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            return response;

            // return response.data;
        } catch (error: any) {
            if (error.response && [403, 401].includes(error.response.status)) {
                console.error("403 Forbidden: Access denied.");
                ApiService.logout();
            } else {
                console.error("Error fetching profile:", error);
            }
            throw error;
        }
    },
};

export default ApiService;
