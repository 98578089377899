// src/pages/LinkedInAuthPopup.tsx

import React, { useEffect } from 'react';

const LinkedInAuthPopup = () => {
    useEffect(() => {
        // Extract the code and state from the URL
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');

        console.log("code:" + code, "state:" + state);

        // Send the code and state back to the main window
        if (window.opener) {
            window.opener.postMessage(
                { type: 'LinkedInAuth', code, state },
                window.location.origin
            );
        }

        // Close the popup window
        window.close();
    }, []);

    return null;
};

export default LinkedInAuthPopup;
