// StepIndicator.js
import React from 'react';
import { Stepper, Loader } from "@mantine/core";
import { IconPlayerPlay, IconFlag, IconCheck, IconPlayerStop, IconExclamationMark, IconX, IconUser } from "@tabler/icons-react";

const StepIndicator = ({ activeStep, maxSteps, activeStatus, configJson }) => {
    return (
        <Stepper
            style={{
                paddingLeft: 13,
                paddingRight: 7,
                paddingTop: 20,
                paddingBottom: 20
            }}
            color={configJson.primaryColor}
            size="xs"
            active={activeStep}
            allowNextStepsSelect={false}
            iconSize={20}
        >
            {Array.from({ length: maxSteps }, (_, i) => (
                <Stepper.Step
                    key={i}
                    icon={
                        i === 0 ? <IconPlayerPlay c={"white"} /> :
                            i === maxSteps - 1 ? <IconFlag /> :
                                activeStep === i ? <Loader size="xs" /> : undefined
                    }
                    completedIcon={
                        i === 0 ?
                            activeStep < maxSteps ?
                                <IconPlayerStop color={"white"} /> :
                                <IconPlayerPlay color={"white"} />
                            :
                            i === maxSteps - 1 ?
                                activeStatus === 'ERROR' ?
                                    <IconX color={"red"} /> :
                                    activeStatus === 'ABORTED' ?
                                        <IconExclamationMark color={"red"} /> :
                                        <IconFlag color={"white"} />
                                :
                                <IconCheck color={"white"} />
                    }
                    progressIcon={
                        activeStatus === 'ERROR' ?
                            <IconX color={"red"} /> : activeStatus === 'ABORTED' ?
                                <IconExclamationMark color={"red"} /> :
                            activeStatus === 'PAUSED' ?
                                <IconUser size="xs" color={"grey"} /> :
                                <Loader size="xs" color={"orange"} />
                    }
                />
            ))}
        </Stepper>
    );
};

export default StepIndicator;
