import {
    AppShell,
    Center
} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {useState} from 'react'
import Header from "./components/Header"
import RouterSwitcher from "./components/RouterSwitcher";
import Navbar from "./components/Navbar";
import {ConfigProvider} from "./ConfigContext";
import {AuthProvider} from "./AuthContext";


export function App() {
    //const [opened, {toggle}] = useDisclosure();
    const [opened, setOpened] = useState(false);
    const toggle = () => {
        setOpened(!opened)
    };

    return (
        <ConfigProvider>
            <AuthProvider>
                <AppShell
                    header={{height: 60}}
                    navbar={{width: 300, breakpoint: 'sm', collapsed: {mobile: !opened}}}
                    padding="md"
                >
                    <Header toggle={toggle} opened={opened}>

                    </Header>
                    <Navbar toggle={toggle} opened={opened} setOpened={setOpened}/>
                    <AppShell.Main>
                        <RouterSwitcher/>
                    </AppShell.Main>
                    <AppShell.Footer>
                        <Center>©2023-{new Date().getFullYear()} PolyQ.ai Inc.</Center>
                    </AppShell.Footer>
                </AppShell>
            </AuthProvider>
        </ConfigProvider>

    );
}

export default App;
