import React, {useCallback, useState, useEffect} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import ApiService from "../services/ApiService";
import apiService from "../services/ApiService";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../AuthContext";

const stripePromise = loadStripe("pk_live_51PlDn4B3m9sHlAejMbqtaLjuYjg9yfp4QiM0QtenrjfCuOoWp788mLjb7uuKO1qxkX8FPMIzifeKMwPeXCtEtpC900gDFJsuoV");

const StripeForm = ({id, isSubscription = false, refreshSubInfo = () => {}, checkoutCompleted = () => {}}: any) => {
    const navigate = useNavigate();
    const {refreshProfile} = useAuth()

    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        if (isSubscription) {
            console.log("Subscription checkout")
            return ApiService.createSubscriptionCheckout(id)
                .then((data) => data);
        } else {
            return ApiService.createCheckoutSession(id)
                .then((data) => data);
        }
    }, []);

    const onComplete = useCallback(() => {
        //apiService.markUserChange().then(() => {})
        if (isSubscription) {
            refreshSubInfo()
            refreshProfile()
        } else {
            refreshProfile()
        }
        checkoutCompleted()
    }, []);

    const options = {fetchClientSecret, onComplete};

    return (
        <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
        >
            <EmbeddedCheckout/>
        </EmbeddedCheckoutProvider>
    )
}

export default StripeForm;