import React, { useState, useEffect, useRef } from 'react';
import {Button, Slider, Center} from '@mantine/core';
import { IconPlayerPlay, IconPlayerPause } from '@tabler/icons-react'; // Import play and pause icons

const AudioPlayer = ({ src }) => {
    const audioRef = useRef(new Audio(src));
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [speed, setSpeed] = useState(1); // Default playback speed

    // Function to format time in minutes and seconds
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Toggle play/pause
    const togglePlayPause = () => {
        const prevState = isPlaying;
        setIsPlaying(!prevState);
        if (!prevState) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    };

    // Update progress as audio plays
    useEffect(() => {
        const updateProgress = () => {
            const { currentTime, duration } = audioRef.current;
            setProgress((currentTime / duration) * 100);
            setCurrentTime(currentTime);
        };

        // Set duration on audio load
        const setAudioData = () => {
            setDuration(audioRef.current.duration);
            setCurrentTime(audioRef.current.currentTime);
        };

        audioRef.current.addEventListener('loadedmetadata', setAudioData);
        audioRef.current.addEventListener('timeupdate', updateProgress);

        // Cleanup event listeners
        return () => {
            audioRef.current.removeEventListener('loadedmetadata', setAudioData);
            audioRef.current.removeEventListener('timeupdate', updateProgress);
        };
    }, []);

    // Adjust playback speed
    useEffect(() => {
        audioRef.current.playbackRate = speed;
    }, [speed]);

    // Set audio speed
    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
    };

    const marks = [
        { value: 0, label: '0:00' },
        { value: progress, label: formatTime(audioRef.current.currentTime) },
        { value: 100, label: formatTime(duration) }
    ];

    // Render the component
    return (
        <div>
            <Slider
                // marks={marks}
                label={null}
                labelAlwaysOn
                value={progress}
                onChange={(value) => {
                    const duration = audioRef.current.duration;
                    audioRef.current.currentTime = (value / 100) * duration;
                    setProgress(value);
                }}
                size="sm"
                style={{ marginBottom: 20, marginTop: 10 }}
            />
            <Center style={{ marginBottom: 20 }}>
                <Button onClick={togglePlayPause} size="xl" variant="subtle">
                    {isPlaying ? <IconPlayerPause size={24} /> : <IconPlayerPlay size={24} />}
                </Button>
            </Center>
            {/*<Tabs value={speed.toString()} onTabChange={(value) => handleSpeedChange(parseFloat(value))} grow>*/}
            {/*    <Tabs.List>*/}
            {/*        <Tabs.Tab value="0.5">0.5x</Tabs.Tab>*/}
            {/*        <Tabs.Tab value="1">1x</Tabs.Tab>*/}
            {/*        <Tabs.Tab value="1.5">1.5x</Tabs.Tab>*/}
            {/*        <Tabs.Tab value="2">2x</Tabs.Tab>*/}
            {/*    </Tabs.List>*/}
            {/*</Tabs>*/}
        </div>
    );
};

export default AudioPlayer;
