// config.tsx
import {
    IconAccessPoint,
    IconAddressBook,
    IconAt,
    IconBoxModel,
    IconBrain,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconCactus,
    IconCoins,
    IconCpu,
    IconFingerprint,
    IconHelpHexagon,
    IconHome,
    IconMessage,
    IconNews,
    IconPhone,
    IconPlant,
    IconShieldCheck,
    IconShoppingCart,
    IconUsers,
    IconBrandYoutube,
    IconBuildingStore,
    IconHexagonNumber3,
    IconHexagonNumber6,
    IconHexagonNumber9,
    IconHexagonNumber5,
    IconSettings,
    IconUserCircle,
    IconMap,
    IconCurrencyDollar,
    IconArrowBigDownLines,
    IconUserCheck,
    IconCarrot,
    IconArtboard, IconBrush
} from '@tabler/icons-react';


export const categories = {
    aiServices: {
        title: "AI Services",
        image: ""
    }
}

export const config = {
    api: {
        // baseUrl: "http://127.0.0.1:5000/"
        // baseUrl: "https://dev-api.polyqai.com/"
        baseUrl: "https://api.polyqai.com/"
    },
    categories: [
        {
            "name": "demo",
            "imageName": "demo.png",
            "title": "Demo",
            "description": "Explore patent pending zero-shot multi-task and multi-model capabilities of PolyQ platform. Test how PolyQ.metrix reduces AI bias. Discover the Well of Knowledge."
        },
        {
            "name": "business",
            "imageName": "business_tasks.png",
            "title": "Business Tasks",
            "description": "PolyQ is a business to business AI platform. Take advantage of professionally design AI Tasks|save time and money, while focusing on your core business and customers."
        },
        {
            "name": "marketing",
            "imageName": "content_creation.png",
            "title": "Content Creation",
            "description": "PolyQ is a business to business AI platform. Take advantage of professionally design AI Tasks|save time and money, while focusing on your core business and customers."
        }
    ],
    navigationLinks: {
        stream: {
            label: 'PolyQ WOK',
            private: false,
            default: true,
            items: [
                {
                    link: "/home",
                    label: "Home",
                    wide: true,
                    icon: IconHome
                },
                {
                    link: "/our-team",
                    label: "Our Team",
                    icon: IconUsers
                },
                {
                    link: "/how-it-works",
                    label: "How It Works",
                    disabled: true,
                    icon: IconPlant
                },
                {
                    link: "/feature-and-benefits",
                    label: "Features and Benefits",
                    disabled: false,
                    icon: IconCarrot
                },
                {
                    link: "/intelligence-consulting",
                    label: "Intelligence Consulting",
                    disabled: false,
                    icon: IconBrain
                },
                {
                    link: "/pricing",
                    label: "Pricing",
                    disabled: false,
                    icon: IconCurrencyDollar
                },
                {
                    link: "/testimonials",
                    label: "Testimonials",
                    disabled: true,
                    icon: IconMessage
                },
                {
                    link: "/investor-information",
                    label: "Investor Information",
                    private: true,
                    disabled: true,
                    icon: IconBuildingBank
                },
                {
                    link: "/faq",
                    label: "FAQs",
                    icon: IconHelpHexagon
                },
                {
                    link: "/news",
                    label: "News",
                    icon: IconNews
                },
                {
                    link: "/contact-us",
                    label: "Contact us",
                    icon: IconAddressBook
                },
                {
                    link: "/demos",
                    label: "Demo Reels",
                    icon: IconBrandYoutube
                }
            ]
        },
        myq: {
            label: 'My Studio',
            private: true,
            disabled: false,
            default: false,
            items: [
                {
                    link: "/tasks",
                    label: "My Tasks",
                    icon: IconUserCheck
                },
                {
                    link: "/queue",
                    label: "Tasks in Process",
                    disabled: true,
                    icon: IconArrowBigDownLines
                },
                {
                    link: "/assets",
                    label: "My Assets",
                    disabled: true,
                    icon: IconBoxModel
                },
                {
                    link: "/creator",
                    label: "Creator",
                    disabled: true,
                    icon: IconBrush
                },
                {
                    link: "/ai-store",
                    label: "AI Store",
                    disabled: true,
                    icon: IconFingerprint
                },
                {
                    link: "/setting",
                    label: "Settings",
                    disabled: true,
                    icon: IconSettings
                },
                {
                    link: "/profile",
                    label: "Profile",
                    icon: IconUserCircle
                },
                {
                    link: "/billing",
                    label: "Billing",
                    icon: IconCoins
                },
            ]
        }

    },
    home: {
        image: "https://polyq-task-icons.s3.amazonaws.com/demo/CreatorTease.gif",
        title: "POLYQ EMPOWERS BUSINESS PROFESSIONALS TO CREATE, ENHANCE, AND MONETIZE AI TASKS EFFORTLESSLY.\n",
        body: "While the industry focuses on connecting businesses to developers (B2D), PolyQ revolutionizes this approach by bringing AI directly to you—the business professional.\n" +
            "|" +
            "Imagine creating AI tasks that not only elevate your work but also allow you to sell your knowledge-based solutions to others—all without writing a single line of code.\n" +
            "|" +
            "With PolyQ's intuitive platform, you can set powerful AI processes in motion with just a few clicks. Select inputs from user-friendly dropdown menus and watch as multiple AI and non-AI services converge to deliver unparalleled insights tailored to your needs.\n" +
            "|" +
            "#MORE THAN A NO-CODE SOLUTION\n" +
            "|" +
            "PolyQ isn't just about simplifying workflows. We empower you to harness advanced AI capabilities without any complexity, putting the full potential of AI directly in your hands.\n" +
            "|" +
            "#TRUST AND INTEGRITY AT THE CORE\n" +
            "|" +
            "In an era where unbiased data is critical, PolyQ assesses every output for bias, ensuring your decisions are founded on integrity and impartiality. We don't just deliver data—we deliver trust..\n" +
            "|" +
            "#LEVERAGE YOUR WELL OF KNOWLEDGE\n" +
            "|" +
            "Enhance your outputs with PolyQ's Well of Knowledge (WOK), tapping into your personal expertise to provide insights that only years of experience can offer.\n" +
            "|" +
            "#With PolyQ, you don't just keep up—you lead the way.",
        features: [
            {
                icon: IconCpu,
                title: "AI Empowered Business Solutions",
                description: "PolyQ's platform streamlines AI integration for businesses, enhancing productivity" +
                    " without needing IT."
            },
            {
                icon: IconShieldCheck,
                title: "Unbiased, Expert-Enhanced Results",
                description: "Qtrust shifts from bias to reliability, boosting LLM outputs with your" +
                    " expert knowledge."
            },
            {
                icon: IconShoppingCart,
                title: "AI Task Marketplace for Business",
                description: "Create, and Sell AI tasks in PolyQ's online store"
            },
            {
                icon: IconBuildingStore,
                title: "Branded On-line Store",
                description: "Easily create and sell AI tasks in your own customizable online store, with" +
                    " simple integration options."
            }
        ]
    },
    consulting: [
        {
            icon: IconHexagonNumber3,
            title: "Intelligence + Nano WOK",
            description: "This offering combines intelligence services with Nano WOK technology to provide enhanced AI solutions. By integrating advanced AI algorithms with the unique capabilities of Nano WOK, businesses can achieve unprecedented levels of insight and efficiency. Whether it's for data analysis, decision support, or operational optimization, Intelligence + Nano WOK is designed to transform your business operations.",
            valuePoints: [
                "Enhances decision-making with AI insights.",
                "Streamlines data analysis processes.",
                "Reduces operational costs."
            ]
        },
        {
            icon: IconHexagonNumber5,
            title: "Digital Adviser Library",
            description: "Our Digital Adviser Library is a comprehensive resource designed to leverage company data, information, and knowledge to assist customers. It provides AI-driven tools and analytics to offer relevant advice for each customer about the company and its products/services, enhancing customer support and driving data-informed decisions. With this library, businesses can improve customer engagement, satisfaction, and retention.",
            valuePoints: [
                "Provides relevant advice for each customer.",
                "Enhances customer support.",
                "Drives data-informed decisions."
            ]
        },
        {
            icon: IconHexagonNumber6,
            title: "HR Intelligence Library",
            description: "Our HR Intelligence Library is a comprehensive resource for optimizing human resources management. It offers AI-driven tools and analytics to improve recruitment, performance tracking, and workforce planning. With this library, HR professionals can make data-driven decisions that enhance employee engagement and productivity.",
            valuePoints: [
                "Improves employee performance tracking.",
                "Optimizes recruitment processes.",
                "Facilitates better workforce planning."
            ]
        },
        {
            icon: IconHexagonNumber9,
            title: "Custom Business Solutions",
            description: "Our Custom Business Solutions offer AI-backed strategies and tools tailored to meet the unique needs of your business. Utilizing company data and advanced analytics, we provide bespoke solutions to enhance operational efficiency and drive growth. Our solutions are designed to help you achieve your business goals through innovative and data-driven approaches.",
            valuePoints: [
                "Delivers AI-backed strategies tailored for your business.",
                "Enhances operational efficiency.",
                "Drives business growth through innovative approaches."
            ]
        },
        {
            icon: IconHexagonNumber9,
            title: "Enterprise AI Training",
            description: "The Enterprise AI Training program is designed to equip employees with the skills they need to leverage AI technologies effectively. Through a combination of theoretical knowledge and practical application, this training ensures that your team can harness AI to drive innovation and productivity within your organization.",
            valuePoints: [
                "Enhances employee AI skills.",
                "Boosts productivity with AI tools.",
                "Encourages innovative problem-solving."
            ]
        }
    ],
    featuresAndBenefits: [
        {
            title: "Three-Tier AI Model Ecosystem",
            description: "Our platform operates across three distinct layers—Enterprise, Partner, and Public—offering AI models tailored to various business needs.",
            valuePoints: [
                "Enterprise|Secure, private AI models leveraging your internal data for personalized insights and automation.",
                "Partners|Access specialized commercial AI models and datasets for vertical-specific solutions.",
                "Public|Utilize general-purpose AI models for broader tasks, facilitating cost-effective experimentation."
            ]
        },
        {
            title: "Modular Task Creation",
            description: "Effortlessly create tasks with a blend of AI and non-AI services using voice for Business Users and drag-and-drop interface for Power Users",
            valuePoints: [
                "Flexibility|Customize tasks for specific needs, from simple to complex processes.",
                "Scalability|Start small and expand to intricate workflows as your requirements grow."
            ]
        },
        {
            title: "Orchestrator Services",
            description: "Manage and coordinate complex workflows with multiple AI models to ensure performance and consistency.",
            valuePoints: [
                "Consistency|Validate AI models and monitor SLA to guarantee consistent results.",
                "Efficiency|Optimize processes by selecting the best AI models for each task."
            ]
        },
        {
            title: "Diverse Task Types",
            description: "Design tasks using a variety of AI models, including private, protected, and public tasks.",
            valuePoints: [
                "Customization|Tailor AI tasks to fit your business needs, with options for internal and partner models.",
                "Security|Ensure data protection with private AI tasks while leveraging public and partner resources as needed."
            ]
        },
        {
            title: "Monetization by Design",
            description: "Manage and track costs associated with task execution through a transparent credits system and earn revenue anytime your task is sold and executed in a marketplace. (ours or yours)",
            valuePoints: [
                "Monetize|Earn revenue from your task creations each time it is sold and utilized by others.",
                "Flexibility|Pay only for what you use."
            ]
        },
        {
            title: "Task Management & IP Protection",
            description: "Securely manage task ownership, including IP rights and version control.",
            valuePoints: [
                "Security|Protect your intellectual property with advanced encryption and secure storage.",
                "Control|Maintain complete control over your AI tasks and assets with full version management."
            ]
        },
        {
            title: "Advanced Task Architecture",
            description: "Orchestrate complex tasks with compound structures like Sequencer and Multiplexer.",
            valuePoints: [
                "Efficiency|Execute multiple tasks in parallel or sequence, optimizing workflows and saving time.",
                "Customization|Build adaptable workflows to meet evolving business needs."
            ]
        },
        {
            title: "IP and Liability Safeguards",
            description: "Clear frameworks for ownership and liability, with limited creator and user liability.",
            valuePoints: [
                "Peace of Mind|Minimize legal risks and ensure fair compensation for task creators.",
                "Fairness|Transparent IP and liability structure protects rights and provides clear usage guidelines."
            ]
        }
    ],


    team: [
        {
            title: "Chief Executive Officer",
            photo: "https://polyq-task-icons.s3.amazonaws.com/team/lana.jpeg",
            name: "Lana Forfutdinov",
            intro: "Enreprenurial minded inidivual with a flair for Patents and a Track Record of Financial Innovation.",
            experience: [
                "GM Financial|Digital Transformation",
                "Commercial Bank|Innovation",
                "Oakland University|Bachelor of Computer Science with focus on Artificial Intelligence"
            ]

        },
        {
            title: "Chief Operations Officer",
            photo: "https://polyq-task-icons.s3.amazonaws.com/team/alex.jpeg",
            name: "Alex Pardalis",
            intro: "Empowerment at the Helm: The Ex-CEO Who Mastered the Art of Leadership and Team Building.",
            experience: [
                "REEL Outline Inc|Pharma Tech",
                "Audacia Bioscience|Clinical research organization that utilizes AI, mobile technology to develop customized solution for the health and wellness space",
                "Controls Force Startup|Transaction Watchdog Platform",
                "Protos Development Inc|Real state Development"
            ]
        },
        {
            title: "Chief Technology Officer",
            photo: "https://polyq-task-icons.s3.amazonaws.com/team/vlad.jpeg",
            name: "Vlad Forfutdinov",
            intro: "Visionary at the Digital Forefront: Crafting the Future of AI and Fraud Detection with Tech Leadership.",
            experience: [
                "General Motors|Lead Digital Ecosystem transformation",
                "Controls Force Startup|Transaction Watchdog Platform",
                "Freudenberg-NOK|Custom Enterprise solutions for Tier-one Manufacturing"
            ]
        },
        {
            title: "Chief Product Officer",
            photo: "https://polyq-task-icons.s3.amazonaws.com/team/ivan.jpeg",
            name: "Ivan Blinov",
            intro: "Shaping the Future of Fortune 100s with Two Decades of IT and Business Mastery.",
            experience: [
                "U.S. Department of the Treasury|Tax and Trade Bureau Office",
                "USPTO|Strategic phases of Trademark Next Generation",
                "Federal Deposit Insurance Corporation|FDIC Next Generation Examination Tools Suite",
                "Target Labs, Inc|Intellectual Property"
            ]
        },
        {
            title: "Chief Financial Officer",
            photo: "https://polyq-task-icons.s3.amazonaws.com/team/joanne.jpeg",
            name: "Joanne King",
            intro: "Dynamic Growth-Oriented Financial Officer Driving Success for Multinational Companies in Cross-Border Environments",
            experience: [
                "CPA Canada",
                "BDO Canada LLP|Director of US and Cross Border Tax",
                "KPMG LLP|Partner, US and Cross Border Tax",
                "Extended Enterprise Group|CFO",
                "PPSA Canada|CFO"
            ]

        }
    ],
    authors: [
        {
            name: "Ivan",
            avatar: IconCactus,
            photo: ""
        }
    ],
    newsCategories: {
        /*        "aiServices": {
                    categoryId: "aiServices",
                    title: "AI Services",
                    info: "asd fasdf laksdjf asldkfja sldfkja sldfkjas ldfkjas dflkasjdf laskdjf",
                    icon: IconBrain
                },*/
        "business": {
            categoryId: "business",
            title: "Business running on AI",
            info: "asd fasdf laksdjf asldkfja sldfkja sldfkjas ldfkjas dflkasjdf laskdjf",
            icon: IconBuildingSkyscraper
        }
    },
    news: [
        {
            categoryId: "business",
            assetId: "123",
            date: "04/01/2024",
            title: "Introducing the PolyQ AI Ecosystem: A New Dawn in AI Accessibility",
            image: "https://polyq-task-icons.s3.amazonaws.com/www/Blog+1+-+Introducing+the+PolyQ+AI+Ecosystem_+A+New+Dawn+in+AI+Accessibility.jpg",
            abstract: "Sharing the founders' journey and vision in creating a platform that bridges the gap between complex AI technologies and real-world applications. Overview of the ecosystem - Task Marketplace, Task Studio, Task Orchestrator, Digital Asset Manager, and more, emphasizing the no-code interface that empowers both tech-savvy users and novices. Highlight how PolyQ is not just a tool but a movement towards making AI accessible to all, fostering creativity and growth.",
            intro: "In the rapidly evolving landscape of technology, the dawn of artificial intelligence (AI) has ushered in not just innovations but also a myriad of challenges, particularly in accessibility and monetization. Amidst this backdrop, PolyQ emerges as a revolutionary force, conceived with the vision of democratizing AI and fostering a new AI economy that is inclusive, innovative, and financially rewarding for creators, innovators, and companies alike.",
            keyPoints: [
                "The Genesis of PolyQ:| Bridging the Gap: PolyQ was born out of a simple yet profound realization: while AI has the potential to transform industries, its complexities and the barriers to entry have left a significant gap in accessibility. This gap not only stifles innovation but also limits the opportunities for creators and businesses to harness the full power of AI. PolyQ's inception was fueled by the ambition to bridge this gap, offering a no-code platform that empowers individuals and businesses, irrespective of their technical expertise, to develop, share, and monetize AI tasks.",
                "Empowering Creators with the PolyQ Marketplace:| At the heart of the PolyQ ecosystem lies the PolyQ Marketplace, a vibrant hub where creativity meets opportunity. The marketplace serves as a platform for monetization, enabling creators to publish and sell their AI-powered tasks. From generating captivating social media images to crafting personalized workout plans, the marketplace offers an array of tasks designed to cater to diverse needs. But PolyQ's commitment goes beyond facilitating transactions; it ensures that creators' intellectual properties are encrypted and protected, setting a new standard in the AI domain.",
                "Innovation Unleashed with Task Studio and Orchestrator:| PolyQ's innovative spirit is epitomized in its Task Studio and Task Orchestrator. The Task Studio, a web-based integrated development environment, empowers creators to invent and refine AI tasks with ease. This no-code interface simplifies the process of creating sophisticated AI-driven solutions, making innovation accessible to all. The Task Orchestrator further enhances this capability by seamlessly integrating various AI and non-AI services, automating workflows, and delivering results that were once deemed complex or unattainable.",
                "A Visionary Initiative for a New AI Economy:| PolyQ stands as a visionary initiative, heralding a new era in AI. By enabling monetization for creators of AI tasks and fostering a new AI economy, PolyQ's impact is poised to be transformative. Our mission aligns with the global aspiration to make AI not only accessible but also a source of creativity, innovation, and financial empowerment for all."
            ],
            cta: "PolyQ is more than just a platform; it is a movement towards a more inclusive, empowered, and technologically advanced future. By subscribing to our blog series on 'AI-powered Products and Services Design Leveraging the PolyQ AI Ecosystem', you become part of a community that is setting the stage for the next wave of AI innovation. Together, let's bridge the AI accessibility gap and unlock a world of possibilities. Welcome to PolyQ, where every task holds the promise of discovery, and every innovation paves the way for a brighter AI-powered future.",
            conclusion: "As we stand on the brink of this new dawn in AI accessibility, we invite you to join us in this exciting journey. Whether you are a creator eager to share your innovative tasks, a business looking to harness the power of AI, or an individual curious about the potential of AI to enhance your daily life, PolyQ offers you the platform to explore, innovate, and thrive.",
            author: "Ivan"
        },
        {
            categoryId: "business",
            assetId: "124",
            date: "04/08/2024",
            title: "Revolutionizing AI Monetization: The PolyQ Marketplace",
            image: "https://polyq-task-icons.s3.amazonaws.com/www/Blog+2+-+Revolutionizing+AI+Monetization_+The+PolyQ+Marketplace.webp",
            abstract: "Dive into the Task Marketplace, explaining how creators can monetize their AI-powered tasks, from social media content generation to complex data analysis. Share real-life examples of individuals and businesses that have transformed their operations and revenue streams through PolyQ. Discuss PolyQ's commitment to IP protection, ensuring creators can innovate with peace of mind.",
            intro: "In the dynamic landscape of artificial intelligence (AI), the quest for innovation is often eclipsed by the challenge of monetization. Enter the PolyQ Marketplace, a visionary initiative designed not just to bridge the technological divide but to forge a new economy—an AI economy rooted in creativity, accessibility, and financial empowerment.",
            keyPoints: [
                "The Genesis of PolyQ Marketplace:| At the heart of the PolyQ AI Ecosystem lies a profound understanding of the dual nature of AI: its boundless potential and its perceived inaccessibility. Recognizing this, we envisioned a platform that dismantles these barriers, offering a no-code interface that democratizes AI, making it an ally for all—from the tech enthusiast in the Silicon Valley to the local entrepreneur in emerging economies.The PolyQ Marketplace is more than just a digital platform; it's a testament to the belief that innovation thrives in inclusivity. By providing a space where creators, regardless of their technical prowess, can monetize their AI tasks, we're not just facilitating transactions; we're nurturing an ecosystem where creativity meets opportunity.",
                "Empowering Creators and Businesses:| The PolyQ Marketplace stands out by ensuring that the intellectual property of creators is not just recognized but protected and monetized. This commitment extends beyond mere words to the very fabric of our platform, incorporating patent-pending technology that safeguards creators' innovations. It's a pledge to respect and reward creativity, encouraging a diverse community of innovators to share their solutions with the world. For businesses, the Marketplace offers a repository of AI-powered solutions that can be seamlessly integrated into existing workflows, driving efficiency, and innovation. From generating captivating social media content to automating complex data analyses, the range of tasks available is as varied as the challenges they address.",
                "A Catalyst for the AI Economy:| The PolyQ Marketplace is more than a conduit for financial transactions; it's a catalyst for the AI economy. This new economy transcends traditional boundaries, offering a platform where anyone can participate, contribute, and benefit. It's an economy that values creativity as much as capital, and where the democratization of AI fosters a new wave of innovation. Our partnerships with educational institutions, tech firms, and governments are designed to amplify this vision, creating a synergistic ecosystem that propels the PolyQ AI Ecosystem to the forefront of the AI revolution. By aligning our mission with the global aspiration to make AI both accessible and empowering, we're not just predicting the future; we're creating it."
            ],
            cta: "Together, we can shape a future where AI is not a distant dream but a tangible reality accessible to all. A future where the PolyQ AI Ecosystem is the bedrock of a thriving, inclusive, and innovative AI economy. Explore the PolyQ Marketplace today and be part of the revolution that's redefining AI monetization.",
            conclusion: "The journey of PolyQ is a testament to the power of vision, innovation, and perseverance. As we stand on the precipice of a new era in AI, we invite you to join us. Whether you're a creator looking to monetize your AI tasks, a business in search of solutions to streamline your operations, or an investor keen on being part of a transformative venture, the PolyQ Marketplace offers you a unique opportunity.",
            author: "Ivan"
        },
        {
            categoryId: "business",
            assetId: "125",
            date: "04/15/2024",
            title: "Crafting AI Solutions with PolyQ: A Deep Dive into Task Studio and Orchestrator",
            image: "https://polyq-task-icons.s3.amazonaws.com/www/Blog+3+-+Crafting+AI+Solutions+with+PolyQ_+A+Deep+Dive+into+Task+Studio+and+Orchestrator.webp",
            abstract: "Provide an insider's look into the Task Studio, showcasing how creators can design AI tasks using a blend of AI prompts and orchestration directives. Explain how the Task Orchestrator seamlessly integrates various services to execute tasks, making complex workflows simple. Illustrate with examples how these tools have been used to solve practical problems and innovate within industries.",
            intro: "In the landscape of modern technology, artificial intelligence (AI) stands as a beacon of potential, driving innovation and efficiency across industries. At PolyQ, we've embarked on a mission to not just participate in this revolution but to democratize it, making AI accessible to all. Our journey is grounded in the belief that the power of AI should not be confined to those with deep technical expertise but should be available to everyone, everywhere. This blog delves into the heart of our ecosystem—the Task Studio and Task Orchestrator—showcasing how these tools are pivotal in crafting AI-powered solutions.",
            keyPoints: [
                "Task Studio - Where Creativity Meets AI:| Imagine a space where the barriers to AI innovation are dismantled, where every creative mind can bring their ideas to life without the need for coding. That's what we've built with Task Studio. It is not just a tool but a canvas for innovation, offering a web-based integrated development environment (IDE) that is both powerful and intuitive. Here, creators can design, test, and deploy AI tasks, weaving together generative AI prompts and orchestration directives to automate workflows in unprecedented ways. From generating personalized birthday cards that combine unique images and heartfelt messages to crafting sophisticated data analysis tools, Task Studio is where ideas transform into reality. It democratizes the creation of AI tasks, enabling creators to focus on innovation rather than the intricacies of programming.",
                "Task Orchestrator - The Symphony of AI Services:| The magic of AI does not lie in isolated tasks but in how these tasks are orchestrated to create solutions that are greater than the sum of their parts. Our Task Orchestrator is the conductor of this symphony, seamlessly integrating various AI and non-AI services to execute complex workflows. This automation engine is the backbone of the PolyQ platform, ensuring that tasks designed in the Task Studio come to life, delivering the intended results efficiently and effectively. Whether it's automating content creation for social media, synthesizing research from multiple documents, or managing customer inquiries with AI-driven assistants, the Task Orchestrator ensures that every step is performed in harmony, producing outcomes that are both innovative and impactful.",
                "The Impact - Transforming Industries, Empowering Creators:| The synergy between Task Studio and Task Orchestrator has already begun to transform industries. Small businesses now leverage custom AI solutions to compete with larger counterparts, educators use AI to create interactive learning experiences, and creatives harness AI to explore new realms of art and design. These tools are not just about automating tasks; they're about unlocking potential, fostering creativity, and driving progress. At PolyQ, we're inspired every day by the stories of those who use our platform. From entrepreneurs who streamline operations to artists who find new mediums for expression, the impact is profound and far-reaching. Our vision for a democratized AI is not just a dream but a reality being built one task at a time."
            ],
            cta: "As we continue to innovate and expand the PolyQ ecosystem, we invite you to join us. Whether you're a creator looking to bring your AI ideas to life or a business seeking to harness the power of AI, Task Studio and Task Orchestrator offer the tools you need to succeed in this new AI economy. The future of AI is not just about technology; it's about the creativity, ingenuity, and vision of those who use it. Together, we can shape this future, making AI not just accessible but instrumental in driving innovation across the globe. Join us on this exciting journey, and let's build the future of AI together.",
            conclusion: "Crafting AI solutions with PolyQ transcends traditional boundaries, offering a platform where innovation is not just possible but encouraged and supported. Through Task Studio and Task Orchestrator, we're not just building AI tools; we're fostering a community where anyone, regardless of their technical background, can participate in and benefit from the AI revolution.",
            author: "Ivan"
        },
        {
            categoryId: "business",
            assetId: "126",
            date: "04/22/2024",
            title: "Bridging the AI Accessibility Gap: PolyQ's Mission",
            image: "https://polyq-task-icons.s3.amazonaws.com/www/Blog+4+-+Bridging+the+AI+Accessibility+Gap_+PolyQ's+Mission.webp",
            abstract: "Discuss PolyQ's core mission to make AI tools and technologies accessible to everyone, highlighting the platform's user-friendly design. Share stories of how PolyQ has empowered people from varied backgrounds to utilize AI in their work, hobbies, or businesses. Offer insights into future developments aimed at further simplifying AI accessibility and engagement.",
            intro: "In today’s rapidly evolving landscape, artificial intelligence (AI) is not just a technological advancement; it's a beacon of innovation, creativity, and untapped potential. Yet, amidst this revolution, a significant challenge persists - the accessibility of AI. At PolyQ, our mission transcends the conventional boundaries of AI application and monetization. It is about democratizing AI, making it accessible, understandable, and usable for everyone, irrespective of their technical expertise.",
            keyPoints: [
                "The Genesis of PolyQ - A Visionary Leap:| Founded on the principle of inclusivity, PolyQ emerged as a visionary platform with a singular focus: to bridge the accessibility gap in AI. Recognizing the dichotomy between the vast capabilities of AI and the limited access due to its complexity, we set out to create a solution that empowers every individual to leverage AI. Our ecosystem is designed to be a conduit for creativity, enabling users to not only engage with AI but to also harness its potential to foster new business models and drive innovation.",
                "PolyQ - Empowering Through Innovation:| At the heart of PolyQ’s ethos is the belief that innovation thrives on accessibility. Our platform epitomizes this, offering a no-code interface that simplifies the interaction with AI technologies. Whether you are a creator looking to design and monetize AI-driven tasks, a business aiming to enhance operational efficiency, or an individual curious about AI’s potential, PolyQ provides the tools and environment to bring your ideas to fruition. Our ecosystem encompasses a Task Marketplace for buying and selling AI solutions, a Task Studio for creating AI tasks, a Task Orchestrator for seamless execution, and a Digital Asset Manager for secure asset management. Each component of PolyQ is meticulously crafted to ensure that users can easily navigate, create, and benefit from AI without needing to decipher complex codes or algorithms.",
                "Fostering a Community of Innovators:| PolyQ’s mission is deeply rooted in the belief that the future of AI should be shaped by a diverse community of innovators. By eliminating the barriers to entry, we are not just opening doors to existing AI enthusiasts but are inviting every creative mind to explore the realm of AI. Our platform is a testament to the power of collaboration, bringing together individuals from various backgrounds to contribute to the AI revolution. Our partnerships with educational institutions, tech firms, and governments are pivotal in expanding our reach and impact. These collaborations are instrumental in creating an ecosystem that supports creativity, monetization, and education in the AI space, ensuring that the benefits of AI are universally accessible."
            ],
            cta: "In embracing PolyQ, you are not just accessing a platform; you are joining a movement towards a more inclusive, innovative, and empowering AI future. We invite you to explore PolyQ, to experiment, create, and perhaps discover new horizons that AI can unveil. Together, let’s shape a future where AI is not a distant concept, but a tangible tool for creativity, growth, and innovation. Join us on this journey, and let’s democratize AI, together.",
            conclusion: "As we look to the future, PolyQ remains committed to its mission of bridging the AI accessibility gap. We are continuously exploring innovative ways to enhance our platform, making AI more intuitive, engaging, and beneficial for all. The journey ahead is filled with possibilities, and at PolyQ, we are excited to lead the charge, empowering individuals and businesses to unlock the full potential of AI.",
            author: "Ivan"
        },
        {
            categoryId: "business",
            assetId: "127",
            date: "04/29/2024",
            title: "Envisioning the Future: Building an AI Economy with PolyQ",
            image: "https://polyq-task-icons.s3.amazonaws.com/www/Blog+5+-+Envisioning+the+Future_+Building+an+AI+Economy+with+PolyQ.webp",
            abstract: "Outline the founders' vision for a new AI economy powered by PolyQ, where innovation, monetization, and collaboration thrive. Highlight PolyQ's efforts in partnering with educational institutions, tech companies, and governments to expand the ecosystem. Inspire readers to join in shaping the future of AI, emphasizing the opportunities for creators, users, and investors within the PolyQ ecosystem.",
            intro: "In an era marked by rapid advancements in artificial intelligence, the PolyQ AI Ecosystem emerges as a beacon of innovation, democratizing AI for creators, businesses, and the general public alike. As the founders of PolyQ, we embarked on this journey fueled by a vision to not only bridge the gap between the complexity of AI and its potential users but also to foster a new, vibrant AI economy. This blog post is an invitation to glimpse into the future we're building—a future where AI is within everyone's reach, empowering creativity, innovation, and financial empowerment.",
            keyPoints: [
                "The Genesis of PolyQ:| Our journey began with a simple observation: the transformative power of AI was gated behind complex technical barriers, making it inaccessible to many. In response, we created PolyQ, a no-code platform that simplifies the development, sharing, and monetization of AI tasks. Our mission was clear—to make AI not just accessible but also a tool for financial empowerment and creativity for everyone.",
                "A New AI Economy:| The PolyQ Marketplace, at the heart of our ecosystem, is more than a platform; it's the foundation of a new AI economy. Here, anyone can buy and sell AI-powered tasks—ranging from generating social media content to extracting data from documents—without writing a single line of code. This marketplace not only opens up new revenue streams for creators but also provides businesses and individuals with tailor-made AI solutions that were previously out of reach.",
                "Fostering Creativity and Innovation:| At PolyQ, we believe in the power of creativity and innovation. Our Task Studio and Task Orchestrator empower users to design and execute complex AI tasks with ease. This not only lowers the entry barrier for using AI but also encourages a culture of innovation, where creative solutions to everyday problems are developed, shared, and monetized.",
                "Collaborations and Partnerships:| Understanding the importance of collaboration in achieving widespread adoption and innovation, PolyQ actively seeks partnerships with educational institutions, tech companies, and governments. These collaborations are vital in creating an ecosystem that nurtures creativity, supports monetization, and promotes the use of AI across various sectors.",
                "The Road Ahead:| As we look to the future, we are excited about the possibilities that the PolyQ AI Ecosystem holds.  Our vision is for a world where AI is not only accessible to everyone but also serves as a catalyst for innovation, creativity, and economic growth. We are committed to expanding our platform, exploring new partnerships, and continually enhancing the user experience to ensure that PolyQ remains at the forefront of the AI revolution."
            ],
            cta: "Together, we can shape a future where AI is not a distant dream but a tangible reality, empowering individuals and transforming economies. Welcome to the new AI economy. Welcome to PolyQ.",
            conclusion: "We stand at the threshold of a new era in AI, and we invite you to join us in this journey. Whether you are a creator looking to monetize your AI tasks, a business seeking to harness the power of AI, or an individual curious about the potential of artificial intelligence, PolyQ offers you the tools, platform, and community to explore, innovate, and thrive.",
            author: "Ivan"
        },
        {
            categoryId: "business",
            assetId: "128",
            date: "11/04/2024",
            title: "Unleash the Power of AI for Your Business: Why Every Company Needs AI",
            image: "https://polyq-task-icons.s3.us-east-1.amazonaws.com/www/Blog+6+-+Unleash+the+Power+of+AI+for+Your+Business-+Why+Every+Company+Needs+AI.png",
            abstract: "PolyQ is transforming how people interact with AI. Through strategic partnerships with educational institutions, tech firms, and governments, PolyQ is expanding its ecosystem, paving the way for a future where AI is integral to everyday life and business. This blog post invites you to be part of this transformation, showcasing the vast opportunities available within the PolyQ ecosystem.",
            intro: "In today's rapidly evolving business landscape, staying ahead of the curve is crucial. One of the most transformative forces driving this change is Artificial Intelligence (AI). AI is revolutionizing businesses by automating tasks, improving efficiency, and driving revenue. Yet, despite its immense potential, many companies struggle to leverage AI's full power due to its complexity and the perceived need for specialized expertise.",
            keyPoints: [
                "The Challenge: Unlocking AI's Potential:| Businesses often view AI as a complex IT project, which can be daunting. Many companies are unsure how to implement AI solutions effectively, leading to missed opportunities for innovation and growth. Furthermore, there's a misconception that AI is only for large enterprises with significant resources, leaving smaller businesses feeling excluded from the AI revolution.",
                "PolyQ: Your Gateway to AI Empowerment:| Enter PolyQ—a user-friendly platform designed to democratize AI for businesses of all sizes. With PolyQ, you don't need to be a tech expert to harness the power of AI. Our platform empowers businesses to create, use, and monetize their own AI tasks without any IT knowledge. Imagine unlocking AI's benefits, such as improved productivity, reduced costs, and enhanced decision-making, all within reach of your business. PolyQ is not just about making AI accessible; it's about making AI work for you. Our solution is designed to empower every creator to leverage their knowledge and easily create and monetize AI tasks. With a focus on B2B, PolyQ enhances business operations by providing consistent, unbiased, and knowledge-based results.",
                "Real-World Impact: A Case Study:| Consider this: PolyQ helped a company cut pitch deck review time by 95%. What used to take hours can now be accomplished in just 15 minutes or less. This is just one example of how PolyQ's AI solutions can transform business processes, saving time and resources while delivering high-quality outcomes.",
                "Join the AI Revolution with PolyQ:| The future of business is AI-driven, and PolyQ is leading the charge. We invite you to discover how PolyQ can help your business harness the power of AI. Sign up for a free demo today and experience firsthand how our platform can revolutionize your operations.",
                "Busting AI Myths:| Conversational AI has transformed how businesses communicate with their customers, offering personalized and efficient interactions. However, many companies still hold misconceptions about its capabilities, fearing that it may not work effectively for their business needs.",
                "Debunking the Myths:| One common myth is that conversational AI is generic and biased, failing to provide the nuanced interactions that customers expect. At PolyQ, we understand these concerns and have developed conversational AI solutions designed specifically for businesses. Our solutions offer tailored interactions that meet the unique needs of each business, ensuring a seamless customer experience.",
                "Overcoming Challenges with PolyQ:| PolyQ's conversational AI solutions empower businesses to overcome the challenges associated with AI communication. By leveraging our platform, companies can enhance customer engagement, streamline operations, and ultimately drive business success.",
                "Transform Your Business Today:| Don't let misconceptions hold you back. Learn more about PolyQ's conversational AI solutions and see how they can transform your business. Take the first step toward improved customer interactions and sign up for a demo today.",
                "Empowering Business Users: The Power of No-Code AI:| AI should be accessible to all businesses, regardless of their technical expertise. Yet, many companies feel intimidated by the perceived complexity of AI implementation. This is where PolyQ's no-code platform comes into play.",
                "Democratizing AI for All:| PolyQ empowers business users to create and manage their own AI tasks without the need for expensive consultants or IT teams. By democratizing AI, PolyQ is unlocking its full potential for all businesses, enabling them to leverage AI in ways they never thought possible.",
                "Experience the Power of No-Code AI:| With PolyQ, you can easily create, sell, buy, and use AI tasks, all without writing a single line of code. Our platform is designed to be intuitive and accessible, allowing you to focus on what you do best—running your business.",
                "Get Started with PolyQ:| Experience the power of no-code AI with PolyQ. Get started with our free trial today and discover how easy it is to integrate AI into your business operations. Join us in making AI work for you and unlock new opportunities for growth and innovation."
            ],
            cta: "Ready to be part of the AI revolution? Join the PolyQ ecosystem today and discover the power of AI at your fingertips. Whether you're a business seeking to streamline operations, a creator ready to innovate, or an investor looking to support the future of AI, PolyQ has a place for you. Sign up for a free demo to experience firsthand how PolyQ can transform your operations, drive growth, and unlock new opportunities for success. Let’s build the future of AI together—start with PolyQ today.",
            conclusion: "As we stand at the forefront of an AI-powered future, PolyQ is committed to making AI accessible, intuitive, and transformative for everyone—from businesses and creators to educators and governments. We envision a world where AI is no longer a complex luxury but an everyday tool for innovation, efficiency, and growth. By building a thriving AI economy, fostering collaboration, and democratizing powerful AI tools, PolyQ is helping shape the future of how we work, create, and interact.",
            author: "Lana"
        },
        {
            categoryId: "business",
            assetId: "129",
            date: "11/05/2024",
            title: "Demystifying AI: A Beginner's Guide for Business Professionals",
            image: "https://polyq-task-icons.s3.us-east-1.amazonaws.com/www/Blog+7+-+A+Beginners+Guide+for+Business+Professionals.png",
            abstract: "AI doesn't need to be a confusing and unreachable technology. We are here to make it simple",
            intro: "In today's rapidly evolving technological landscape, understanding Artificial Intelligence (AI) is no longer optional for business professionals—it's essential. This blog aims to demystify AI, breaking down complex concepts into digestible insights that empower you to harness this transformative technology.",
            keyPoints: [
                "Defining AI and Its Key Concepts: | AI, or Artificial Intelligence, refers to the simulation of human intelligence processes by machines, particularly computer systems. These processes include learning (the acquisition of information and rules for using the information), reasoning (using rules to reach approximate or definite conclusions), and self-correction. Key concepts include machine learning, natural language processing, and robotics, each playing a pivotal role in how AI systems function.",
                "Different Types of AI and Their Applications: | AI can be categorized into several types, including narrow AI, which is designed to perform a narrow task (like facial recognition or internet searches), and general AI, which is a system with generalized human cognitive abilities. In business, AI applications range from customer service chatbots and personalized marketing to predictive analytics and supply chain optimization.",
                "Benefits and Challenges of AI Adoption for Businesses: | Adopting AI can lead to significant benefits, such as increased efficiency, cost savings, and enhanced customer experiences. However, challenges exist, including the need for significant investment, potential job displacement, and ethical concerns. Navigating these challenges requires a strategic approach and a willingness to adapt to new technological paradigms.",
                "Real-World Examples of AI Applications in Various Industries: | From healthcare to finance, AI is being leveraged to automate processes, enhance decision-making, and improve outcomes. In healthcare, AI algorithms assist in diagnosing diseases with greater accuracy. In finance, AI-driven analytics provide deeper insights into market trends.",
                "The Impact of AI on Job Roles and Workforce Transformation: | AI is reshaping job roles, augmenting human capabilities rather than replacing them. It enables workers to focus on higher-level tasks while automating routine operations. This transformation requires a shift in workforce skills, emphasizing creativity, problem-solving, and emotional intelligence.",
                "Embracing AI for Competitive Advantage: | Businesses that embrace AI gain a competitive edge by improving productivity, reducing costs, and delivering superior customer experiences. The key is to integrate AI strategically, aligning it with business goals and ensuring ethical considerations are addressed.",
                "Emerging AI Technologies and Their Potential Applications: | Emerging technologies like deep learning, quantum computing, and edge AI are set to revolutionize industries. These advancements promise more powerful, efficient, and accessible AI solutions that can be applied across various sectors.",
                "Ethical Considerations and Regulatory Challenges: | With great power comes great responsibility. As AI technologies advance, ethical considerations—such as data privacy, bias, and accountability—become increasingly important. Regulatory frameworks must evolve to address these challenges, ensuring AI is developed and used responsibly.",
                "Success Stories of Businesses Implementing AI: | Companies across different sectors have successfully implemented AI, achieving remarkable results. For example, a retail company used AI to optimize its supply chain, reducing costs by 20% and improving delivery times.",
                "Quantifying the Impact of AI on Key Business Metrics: | The impact of AI is quantifiable, with businesses reporting increased revenue, enhanced customer satisfaction, and improved operational efficiency. These metrics underscore the value of investing in AI technologies.",
                "The Importance of Human Involvement in AI Development: | Human involvement is vital in AI development, ensuring systems are designed with ethical considerations and aligned with human values. Collaboration between technologists and domain experts is key to creating AI solutions that truly benefit society.",
                "Benefits of a Human-Centric Approach to AI Adoption: | A human-centric approach to AI adoption focuses on enhancing human capabilities and improving quality of life. This approach leads to more sustainable and ethical AI solutions that align with business goals and societal needs.",
                "Partner with us to create a human-centric AI strategy that aligns with your business goals.|"
            ],
            cta: "AI holds immense potential to transform the way businesses operate, from improving efficiency and productivity to enhancing customer experiences and enabling data-driven insights. If you're ready to leverage AI's benefits, now is the perfect time to act. Schedule a consultation with our AI experts to discover tailored solutions that align with your business goals and drive sustainable growth. Let us help you navigate the AI landscape and unlock new opportunities for your organization.",
            conclusion: "Artificial Intelligence is more than just a technological advancement; it represents a paradigm shift in how businesses operate and innovate. As AI continues to evolve, businesses that adopt and integrate AI effectively will stay ahead of the curve, achieving a competitive edge in their respective industries. While the journey to AI adoption may seem challenging, the rewards—enhanced efficiency, customer satisfaction, and innovative possibilities—are well worth the effort. By focusing on strategic implementation, ethical considerations, and a human-centric approach, businesses can harness AI to build a brighter, more resilient future.",
            author: "Lana"
        },

    ],
    demosCategories: {
        /*        "aiServices": {
                    categoryId: "aiServices",
                    title: "AI Services",
                    info: "asd fasdf laksdjf asldkfja sldfkja sldfkjas ldfkjas dflkasjdf laskdjf",
                    icon: IconBrain
                },*/
        "creator": {
            categoryId: "creator",
            title: "Creator with Mila Digital Assistant",
            info: "asd fasdf laksdjf asldkfja sldfkja sldfkjas ldfkjas dflkasjdf laskdjf",
            icon: IconBuildingSkyscraper
        },
        "tasks": {
            categoryId: "tasks",
            title: "AI Task Demos",
            info: "asd fasdf laksdjf asldkfja sldfkja sldfkjas ldfkjas dflkasjdf laskdjf",
            icon: IconBuildingSkyscraper
        }
    },
    demos: [
        {
            categoryId: "creator",
            assetId: "123",
            date: "10/09/2024",
            title: "Creating & Testing AI Tasks with PolyQ Creator - All Using Voice Commands! Birthday Card Demo.",
            image: "https://www.youtube.com/embed/opfbbyyi4oY",
            abstract: "In this demo, witness the power of PolyQ's Creator as we use only voice commands to seamlessly create, run, and evaluate a task! With the help of Mila, our digital assistant, talking back to guide and execute commands, Watch as we navigate the creation of a new automated task AI-driven task simply by speaking, with no typing involved.\n" +
                "\n" +
                "This video showcases the intuitive experience of using PolyQ Creator to set up a task, execute it, and view the results AI-task, execute it, and view the results - all in a streamlined process guided by voice commands. Mila will be there every step of the way, providing feedback and executing your spoken instructions. See how effortlessly you can create tasks and achieve efficient results without touching your keyboard. Perfect for those curious about the future of hands-free task automation! and AI-powered task automation!",
            author: "Alex"
        },
        {
            categoryId: "tasks",
            assetId: "234",
            date: "04/18/2024",
            title: "Never Miss a Superstar Startup Again: PolyQ AI’s Revolutionary Pitch Deck Evaluation.",
            image: "https://www.youtube.com/embed/6LNCSmyK0Bs",
            abstract: "Explore the next generation of investment tools with PolyQ AI! This insightful video delves into how PolyQ's AI technology evaluates pitch decks, offering investors a comprehensive and automated solution to identify promising startups. Learn about the advanced features that save time and boost decision accuracy, ensuring you never miss out on a potential superstar. Perfect for savvy investors, PolyQ AI streamlines the evaluation process, making it easier to spot and support the next big success in the business world.\n" +
                "\n" +
                "Introducing PolyQ AI: Brief introduction to PolyQ and its strategic benefits for the investment community.\n" +
                "Pitch Deck Evaluation Process: Showcasing how PolyQ’s AI evaluates pitch decks to highlight key opportunities and risks, aiding swift and informed investment decisions.\n" +
                "Feature Demonstration: This is a detailed look at features like sound file generation, text extraction, and automated PDF descriptions that enhance the evaluation process.\n" +
                "Efficiency and Scalability: PolyQ's AI is highlighted for its scalability and efficiency, showing how it automates and accelerates the investor review process.\n" +
                "Investor Call to Action: Urging investors to adopt PolyQ’s AI tool to ensure they capture every opportunity with potential high-return startups.",
            author: "Lana"
        },
        {
            categoryId: "tasks",
            assetId: "56734",
            date: "04/01/2024",
            title: "Welcome to PolyQ - Making AI Easy for Business - Learning Aid Demo.",
            image: "https://www.youtube.com/embed/wpBjS0PmbjM",
            abstract: "Welcome to our demonstration of PolyQ, the ultimate Learning Aid Producer designed to streamline the creation of educational materials using cutting-edge AI technology. In this video, we introduce you to a seamless process where you only need to input a subject, and PolyQ handles the rest, leveraging various AI services to bring comprehensive learning aids to life.\n" +
                "\n" +
                "Here's what PolyQ does for you:\n" +
                "\n" +
                "Learning Results Generation: Uses OpenAI's GPT to produce detailed educational content based on your subject.\n" +
                "Visual Learning Aids: Creates engaging and relevant images using OpenAI's DALL·E, enhancing the learning experience.\n" +
                "Image Description: Collaborates with Gemini Pro Vision to craft precise descriptions of the generated images, adding depth to the visual aids.\n" +
                "Audio Description: Employs Whisper text-to-speech technology, offering an auditory recap of the image descriptions, making the learning aids accessible and dynamic.\n" +
                "PolyQ stands at the intersection of innovation and education, making AI accessible and functional for businesses looking to enhance their learning materials. Experience how a simple subject input transforms into a detailed, multi-modal learning aid.",
            author: "Lana"
        }
    ],
    faqs: [
        {
            question: "What LLM's and tools does PolyQ connect to?",
            answer: "We actually connect to any and all AI and non-AI services; even your own private  For us it does not matter what the service we need to connect to is. Think of use as the 'glue' between your buisiness operations and other tools and technologies.",
            icon: IconHelpHexagon
        },
        {
            question: "What industry are we focused on?",
            answer: "One of the cool parts about our platform, is that we are not industry specific. We have built a flexible platform structure that allows us to cater to the needs of any market vertical (industry) as long as we understand the business/customer needs and goals for the final output.",
            icon: IconHelpHexagon
        },
        {
            question: "Are we AI consultants?",
            answer: "Technically right now, yes. However in the future the goal of our platform is to empower all individuals to create their own AI ''tasks''. Since our entire project is currently bootstrapped we have not been able to implement the fully fledged creater side or PolyQ. So for now we work as AI consultants finding AI powered solutions for your business needs.",
            icon: IconHelpHexagon
        },
        {
            question: "What do we do about bias?",
            answer: "Part of our patent pending ''secret sauce'' is a layer of technology that helps removes bias that general AI generated output will give you. PolyQ's task output isn't just your generic chatGPT response, it is specific and can be even based on your own knowledge bases. All output generated by the PolyQ platform engine goes through a layer of bias reduction and a knowledge based layer specific to your use case. ",
            icon: IconHelpHexagon
        },
        {
            question: "What is PolyQ task?",
            answer: "A task is what we refer to as product of several AI or non-AI tools and services stacked together to make your job/process easier. With just a few inputs on your end and the click of a button, PolyQ executes a task that has multiple levels of built in layering along with bias reduction and specifying information to your knowledge bases.",
            icon: IconHelpHexagon
        },
        {
            question: "How PolyQ Tasks can benefit business owners?",
            answer: "PolyQ Tasks can benefit business owners by providing them with a user-friendly platform to create AI-powered solutions tailored to their specific needs. With PolyQ, business owners can automate processes, analyze data, and gain a competitive advantage within their budget constraints, all without the need for technical expertise.",
            icon: IconHelpHexagon
        }
    ],
    contacts: [
        {title: 'Email', description: 'sales@polyq.ai', icon: IconAt},
        {title: 'Phone', description: '+1 734 344 5083', icon: IconPhone},
        {title: 'Address', description: '21366 Hall Road, Unit #4951, Clinton Township, MI 48038', icon: IconMap}
    ],
    allFeatures: [
        {name: 'AI Service Selection', available: true, description: ''},
        {
            name: 'Persona Choice',
            available: false,
            description: 'A profile based on specific characteristics and expertise'
        },
        {
            name: 'Output Format',
            available: true,
            description: 'Describes how the output would be presented to the user'
        },
        {
            name: 'Personalization',
            available: true,
            description: 'Ability to leverage users preferences, needs and business knowledge to create a WOK for personalized outputs'
        },
        {
            name: 'Task Creation',
            available: false,
            description: 'Give a user access to the Creator Studio, ability to create, publish and monetize their ideas'
        },
        {name: 'Task Library Access', available: false, description: ''},
        {name: 'Task Marketplace Publishing', available: false, description: ''},
        {name: 'Digital Asset Management', available: false, description: ''},
        {name: 'Private AI Service Access', available: true, description: 'Private Large Language Model'},
        {name: 'Automation', available: false, description: ''}
    ],
    plans: [
        {
            name: 'free',
            available: true,
            features: [
                {name: 'AI Services Selection', value: 'Default', description: ''},
                {name: 'Persona Choice', value: 'Default', description: ''},
                {name: 'Output Format', value: 'Default', description: ''},
                {name: 'Personalization', value: 'n/a', description: ''},
                {name: 'Task Creation', value: 'n/a', description: ''},
                {name: 'Task Library Access', value: 'Limited', description: ''},
                {name: 'Task Marketplace Publishing', value: 'n/a', description: ''},
                {name: 'Digital Asset Management', value: 'n/a', description: ''},
                {name: 'Private AI Service Access', value: 'n/a', description: ''},
                {name: 'Automation', value: 'n/a', description: ''},
            ],
            price: {
                monthly: 0,
                annual: 0
            },
            stripe_prices: {
                monthly: '',
                annual: ''
            }
        },
        {
            name: 'discovery',
            available: true,
            features: [
                {name: 'AI Services Selection', value: 'Default', description: ''},
                {name: 'Persona Choice', value: '3 Personas', description: ''},
                {name: 'Output Format', value: 'Default', description: ''},
                {name: 'Task Library Access', value: 'Unlimited', description: ''},
                {name: 'Task Creation', value: 'n/a', description: ''},
                {name: 'Personalization', value: 'Nano', description: ''},
                {name: 'Task Marketplace Publishing', value: 'n/a', description: ''},
                {name: 'Digital Asset Management', value: 'n/a', description: ''},
                {name: 'Private AI Service Access', value: 'n/a', description: ''},
                {name: 'Automation', value: 'n/a', description: ''},
            ],
            price: {
                monthly: 3,
                annual: 30
            },
            stripe_prices: {
                monthly: 'price_1Q05grB3m9sHlAejwu3Z6dgF',
                annual: 'price_1Q05grB3m9sHlAej8SGkd4UM'
            }
        },
        {
            name: 'explorer',
            favorite: true,
            available: true,
            features: [
                {name: 'AI Services Selection', value: 'Choice', description: ''},
                {name: 'Persona Choice', value: '6 Personas', description: ''},
                {name: 'Output Format', value: '3 Options', description: ''},
                {name: 'Task Library Access', value: 'Unlimited', description: ''},
                {name: 'Personalization', value: 'Pro', description: ''},
                {name: 'Task Creation', value: 'Yes', description: ''},
                {name: 'Task Marketplace Publishing', value: 'n/a', description: ''},
                {name: 'Digital Asset Management', value: 'Yes', description: ''},
                {name: 'Private AI Service Access', value: 'n/a', description: ''},
                {name: 'Automation', value: 'Default', description: ''},
            ],
            price: {
                monthly: 9,
                annual: 90
            },
            stripe_prices: {
                monthly: 'price_1Q05gfB3m9sHlAejg4Wp3vjI',
                annual: 'price_1Q05gfB3m9sHlAej64Z65J7G'
            }
        },
        {
            name: 'creator',
            available: true,
            features: [
                {name: 'AI Services Selection', value: 'Choice', description: ''},
                {name: 'Persona Choice', value: 'Unlimited', description: ''},
                {name: 'Output Format', value: 'Unlimited', description: ''},
                {name: 'Task Library Access', value: 'Unlimited', description: ''},
                {name: 'Personalization', value: 'Ultra', description: ''},
                {name: 'Task Creation', value: 'Yes', description: ''},
                {name: 'Digital Asset Management', value: 'Yes', description: ''},
                {name: 'Automation', value: 'Custom', description: ''},
                {name: 'Private AI Service Access', value: 'Yes', description: ''},
                {name: 'Public Marketplace', value: 'Yes', description: ''},
            ],
            price: {
                monthly: 18,
                annual: 180
            },
            stripe_prices: {
                monthly: 'price_1Q05gJB3m9sHlAejcvTqPH4t',
                annual: 'price_1Q05gJB3m9sHlAejg5iSPpny'
            }
        },
        {
            name: 'enterprise',
            available: true,
            features: [
                {name: 'AI Services Selection', value: 'Choice', description: ''},
                {name: 'Persona Choice', value: 'Unlimited', description: ''},
                {name: 'Output Format', value: 'Unlimited', description: ''},
                {name: 'Task Library Access', value: 'Unlimited', description: ''},
                {name: 'Personalization', value: 'Ultra', description: ''},
                {name: 'Task Creation', value: 'Yes', description: ''},
                {name: 'Digital Asset Management', value: 'Yes', description: ''},
                {name: 'Automation', value: 'Custom', description: ''},
                {name: 'Private AI Service Access', value: 'Yes', description: ''},
                {name: 'Public Marketplace', value: 'Yes', description: ''},
                {name: 'Branded Private Marketplace', value: 'Yes', description: ''},
            ],
            price: {
                monthly: 36,
                annual: 360
            },
            stripe_prices: {
                monthly: 'price_1Q05gYB3m9sHlAejw6Vguhfh',
                annual: 'price_1Q05gYB3m9sHlAejTtWoZ703'
            }
        }
    ],
    priceOrder: [
        'price_1Q05grB3m9sHlAejwu3Z6dgF', //discovery monthly
        'price_1Q05grB3m9sHlAej8SGkd4UM', //discovery annual
        'price_1Q05gfB3m9sHlAejg4Wp3vjI', //explorer monthly
        'price_1Q05gfB3m9sHlAej64Z65J7G', //explorer annual
        'price_1Q05gJB3m9sHlAejcvTqPH4t', //creator monthly
        'price_1Q05gJB3m9sHlAejg5iSPpny', //creator annual
        'price_1Q05gYB3m9sHlAejw6Vguhfh', //enterprise monthly
        'price_1Q05gYB3m9sHlAejTtWoZ703', //enterprise annual
    ],
    creditPackages: [
        {
            productId: 'prod_QcUjqVXpsJa0hM',
            credits: 5,
        },
        {
            productId: 'prod_QdE25tetGMD3JC',
            credits: 10,
        },
        {
            productId: 'prod_QdE3qjc38m6Yd4',
            credits: 25,
        },
        {
            productId: 'prod_QdE4uE3B4Ic7oB',
            credits: 100,
        },
        {
            productId: 'prod_Qh1ntf8V4M3viq',
            credits: 500,
        },
    ]
};
