import React, { useState } from 'react';
import {
    Text,
    Title,
    SimpleGrid,
    TextInput,
    Textarea,
    Button,
    Group,
    ActionIcon, useMantineTheme,
} from '@mantine/core';
import {
    IconBrandYoutube,
    IconBrandLinkedin,
    IconBrandX
} from '@tabler/icons-react';
import { ContactIconsList } from "../components/ContactIcons";
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com';

const social = [
    { icon: IconBrandX, url: "" },
    { icon: IconBrandYoutube, url: "" },
    { icon: IconBrandLinkedin, url: "https://www.linkedin.com/company/polyq-ai-inc" }
];

export function ContactUs() {
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const styles = {
        wrapper: {
            minHeight: '400px',
            borderRadius: 'var(--mantine-radius-md)',
            padding: 'calc(var(--mantine-spacing-xl) * 2.5)'
        },
        title: {
            fontFamily: 'Greycliff CF, var(--mantine-font-family)',
            lineHeight: 1
        },
        description: {
            maxWidth: '300px'
        },
        form: {
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: theme.colors.gray[6],
            padding: 'var(--mantine-spacing-xl)',
            borderRadius: 'var(--mantine-radius-md)',
            boxShadow: 'var(--mantine-shadow-lg)'
        },
        social: {},
        input: {
            '::placeholder': {
                color: 'var(--mantine-color-gray-5)'
            }
        },
        inputLabel: {},
        control: {}
    };

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        message: ''
    });

    const [statusMessage, setStatusMessage] = useState('');

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        emailjs.send('service_yo75scv', 'template_29y6atd', formData, 'MdTCyrnETLHFuGThD')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setStatusMessage('Message sent successfully!');
                setFormData({ email: '', name: '', message: '' });
            })
            .catch((err) => {
                console.error('FAILED...', err);
                setStatusMessage('Failed to send message. Please try again later.');
            });
    };

    const icons = social.map((socialLink, index) => (
        <ActionIcon key={index} size={28} style={styles.social} variant="transparent" onClick={() => window.open(socialLink.url, '_blank')}>
            <socialLink.icon size="1.4rem" stroke={1.5} />
        </ActionIcon>
    ));

    return (
        <div style={styles.wrapper}>
            <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
                <div>
                    <Title style={styles.title}>Contact us</Title>
                    <Text style={styles.description} mt="sm" mb={30}>
                        Leave your email and we will get back to you within 24 hours
                    </Text>

                    <ContactIconsList />

                    <Group mt="xl">{icons}</Group>
                </div>
                <form style={styles.form} onSubmit={handleSubmit}>
                    <TextInput
                        label="Email"
                        name="email"
                        placeholder="Provide your email address here"
                        required
                        styles={{ input: styles.input, label: styles.inputLabel }}
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Name"
                        name="name"
                        placeholder="Enter your name here"
                        required
                        mt="md"
                        styles={{ input: styles.input, label: styles.inputLabel }}
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <Textarea
                        required
                        label="Your message"
                        name="message"
                        placeholder="Create a message that will communicate your request"
                        minRows={8}
                        mt="md"
                        styles={{ input: styles.input, label: styles.inputLabel }}
                        value={formData.message}
                        onChange={handleChange}
                    />

                    <Group justify="flex-end" mt="md">
                        <Button type="submit" style={styles.control}>Send message</Button>
                    </Group>
                    {statusMessage && (
                        <Text mt="md" style={{ color: statusMessage.includes('successfully') ? 'green' : 'red' }}>
                            {statusMessage}
                        </Text>
                    )}
                </form>
            </SimpleGrid>
        </div>
    );
}
