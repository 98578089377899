import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Import useAuth
import {
    Container,
    Paper,
    TextInput,
    Button,
    Image,
    PinInput,
    Text,
    Space,
    Anchor,
    Center,
    useMantineTheme,
} from "@mantine/core";

interface LoginProps {
    onClose: () => void;
    initialSignup?: boolean;
}

const Login: React.FC<LoginProps> = ({ onClose, initialSignup = false }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [otpRequested, setOtpRequested] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [isSignup, setIsSignup] = useState(initialSignup);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const navigate = useNavigate();
    const { requestOTP, login, signup } = useAuth(); // Use requestOTP, login, and signup functions from AuthContext
    const theme = useMantineTheme();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!otpRequested) {
            await handleRequestOTP();
        } else {
            await handleVerifyOTP(otp);
        }
    };

    const handleRequestOTP = async () => {
        setIsLoading(true); // Start loading
        try {
            await requestOTP(email);
            setOtpRequested(true);
            setOtpError(false);
            setOtp("");
        } catch (error: any) {
            console.error("Error:", error.message);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    const handleVerifyOTP = async (otp: string) => {
        setIsLoading(true); // Start loading
        try {
            if (isSignup) {
                await signup(email, otp, firstName, lastName, companyName); // Use signup from AuthContext which handles OTP verification and fetching profile
            } else {
                await login(email, otp); // Use login from AuthContext which handles OTP verification and fetching profile
            }
            onClose(); // Close the modal
            navigate("/"); // Navigate to tasks or update the page state
        } catch (error) {
            console.log("Show Error State");
            setOtpError(true);
            setOtp("");
        } finally {
            setIsLoading(false); // End loading
        }
    };

    return (
        <Container style={{ minWidth: 300 }} size={420} my={10}>
            <Paper component="form" onSubmit={handleSubmit} p={10} mt={10} radius="xl">
                <Center>
                    <Image
                        src={"https://polyq-task-icons.s3.amazonaws.com/polyqFaceLogoOneNormal.png"}
                        ta="center"
                        style={{
                            paddingTop: 0,
                            paddingBottom: 5,
                            width: 192,
                        }}
                    />
                </Center>

                <Space h="lg" />
                <Text size="xs">
                    Welcome to PolyQ.ai ecosystem. Please provide your email. We will send you a One Time Pin (OTP) to validate it and automatically sign you in.
                </Text>
                <Space h="lg" />
                {isSignup && (
                    <>
                        <TextInput
                            label="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Enter your first name"
                            disabled={isLoading}
                        />
                        <Space h="lg" />
                        <TextInput
                            label="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Enter your last name"
                            disabled={isLoading}
                        />
                        <Space h="lg" />
                        <TextInput
                            label="Company Name (optional)"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Enter your company name (optional)"
                            disabled={isLoading}
                        />
                        <Space h="lg" />
                    </>
                )}
                <TextInput
                    label="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setOtpRequested(false);
                    }}
                    placeholder="Enter your email to sign in"
                    disabled={!otpError && (otpRequested || isLoading)}
                />

                {otpRequested && (
                    <>
                        <Space h="lg" />
                        <Text fw={700} size="sm">
                            One Time Pin (OTP)
                        </Text>

                        <Text size="xs">
                            For your security, please verify your email address. We sent a One Time Pin (OTP) to validate to the email below. Please check your email box for a message from verify@polyq.ai (it may be in your spam). Use the 6 digit pin and enter it below:
                        </Text>

                        <Space h="lg" />
                        <PinInput
                            size="lg"
                            length={6}
                            oneTimeCode
                            type="number"
                            gap="lg"
                            autoFocus
                            manageFocus
                            error={otpError}
                            value={otp}
                            onChange={(newValue) => {
                                setOtp(newValue);
                                setOtpError(false);
                            }}
                            disabled={isLoading}
                            onComplete={(newValue) => {
                                handleVerifyOTP(newValue).then(r => {});
                            }}
                        />
                        {otpError && (
                            <Text c="red" ta="center">
                                Invalid Pin (
                                <Anchor
                                    c="red"
                                    onClick={() => {
                                        setOtpError(false);
                                        setOtp("");
                                        handleRequestOTP().then(r => {});
                                    }}
                                >
                                    resend
                                </Anchor>
                                )
                            </Text>
                        )}
                    </>
                )}
                <Button
                    style={{ backgroundColor: theme.colors.brand[4] }}
                    type="submit"
                    fullWidth
                    mt="xl"
                    disabled={isLoading || (otp.length < 6 && otpRequested)}
                >
                    {isLoading
                        ? "Processing..."
                        : otpRequested
                            ? "Verify"
                            : "Validate email with OTP"}
                </Button>
                <Center mt="md">
                    <Anchor
                        onClick={() => {
                            setIsSignup(!isSignup);
                            setOtpRequested(false);
                            setOtp("");
                            setOtpError(false);
                        }}
                        underline="never"
                        style={{ color: theme.colors.brand[4] }}
                    >
                        {isSignup ? "Already have an account? Log in" : "Don't have an account? Sign up"}
                    </Anchor>
                </Center>
            </Paper>
        </Container>
    );
};

export default Login;
