import BasicModal from "./BasicModal";
import {
    ScrollArea,
    Text,
    Image,
    Center,
    Paper,
    Accordion, Stepper
} from "@mantine/core";
import React, {useState, useEffect} from "react";
import LoadAsset from "./LoadAsset";
import AudioPlayer from "./AudioPlayer";
import {useConfig} from "../ConfigContext";

function SampleTaskModal({modalOpened, setModalOpened, task, resultAssetList = []}) {
    const [waiting, setWaiting] = useState(false)
    const [maxSteps, setMaxSteps] = useState(0)
    const [activeStep, setActiveStep] = useState(0);
    const [currentSection, setCurrentSection] = useState('TASK');
    // const [resultValue, setResultValue] = useState([])
    const [data, setData] = useState({parameters: []});

    const {api} = useConfig()


    useEffect(() => {
        //Reset task inputs and outputs
        setData({...task, parameters: task.parameters || []});
    }, [task]);

    resultAssetList = [
        {
            "parameter": {
                "name": "Learning Aid Recording",
                "metadata": {"Content-Type": "audio/mp3"},
                "value": [{"source": "open_ai_tts", "data": "a81b3ba3-5d51-473a-a6d2-0af5f31ff375"}]
            }
        }
    ]

    return (
        <BasicModal
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
            title={task.name + " Sample Results"}
            padding={0} // Remove padding to allow the button to be truly at the bottom
            size="lg" // Adjust the size of the modal as needed
            searchable={ScrollArea.Autosize}
            styles={{
                modal: {
                    display: "flex",
                    flexDirection: "column",
                    radius: "lg",
                    height: "80vh", // Adjust the height of the modal
                    overflow: "hidden", // Prevents double scrollbar
                },
            }}
        >

            <ScrollArea scrollbars={"y"} scrollbarSize={10}>
                {
                    resultAssetList.map(result => (
                        result.parameter.value.map((item, index) => {
                            const contentType = result['parameter']['metadata']['Content-Type'].split('/')[0];
                            switch (contentType) {
                                case 'image':
                                    return (
                                        <Paper shadow="xs" radius="md" withBorder p="xl"
                                               style={{marginBottom: 25, paddingBottom: 10}}>
                                            <Center>
                                                <Text size="lg"
                                                      c='#7DA1E8'>{result.parameter.name}</Text>
                                            </Center>
                                            <Image src={`${api.baseUrl}/api/asset/get/${item.data}`}/>
                                            <Text size="xs" color='#7DA1E8' style={{paddingBottom: 10}}>
                                                Source: {item.source.split('_').join(' ')}
                                            </Text>
                                        </Paper>
                                    );

                                case 'audio':
                                    return (
                                        <div>
                                            <Center>
                                                <Text size="lg"
                                                      c='#7DA1E8'>{result.parameter.name}</Text>
                                            </Center>
                                            <Paper shadow="xs" radius="md" withBorder p="xl"
                                                   style={{marginBottom: 5, paddingBottom: 5}}>
                                                <AudioPlayer
                                                    src={`${api.baseUrl}/api/asset/get/${item.data}`}/>
                                            </Paper>
                                            <Text size="xs" c='#7DA1E8' style={{paddingBottom: 5}}>
                                                Source: {item.source.split('_').join(' ')}
                                            </Text>
                                        </div>
                                    )
                                        ;

                                default:
                                    // Handling other content types or providing a default message
                                    return (
                                        <div>
                                            <Center>
                                                <Text size="lg"
                                                      c='#7DA1E8'>{result.parameter.name}</Text>
                                            </Center>
                                            <Text style={{paddingTop: 25}}>
                                                <LoadAsset
                                                    src={`${api.baseUrl}/api/asset/get/${item.data}`}/>
                                            </Text>
                                            <Text style={{paddingBottom: 10}} size="xs"
                                                  c='#7DA1E8'>Source: {item.source.split('_').join(' ')}</Text>
                                        </div>
                                    );
                            }
                        })
                    ))
                }
            </ScrollArea>
        </BasicModal>
    );
}

export default SampleTaskModal;
