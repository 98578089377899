// ParameterForm.js
import React from 'react';
import { TextInput, Select } from "@mantine/core";
import TextSingleFileUploadOrAssetComponent from "./TextSingleFileUploadOrAssetComponent";
import ImageSingleFileUploadOrAssetComponent from "./ImageSingleFileUploadOrAssetComponent";
import PDFSingleFileUploadOrAssetComponent from "./PDFSingleFileUploadOrAssetComponent";

const ParameterForm = ({ parameters, formValues, handleFormChange, optionsFilter }) => {
    return (
        <>
            {parameters.map(param => {
                if (param.ui) {
                    switch (param.ui.type.toLowerCase()) {
                        case 'text':
                            return (
                                <TextSingleFileUploadOrAssetComponent
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    variant="unstyled"
                                    style={{ marginTop: '15px' }}
                                    resize="vertical"
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onValueChange={(newValue) => handleFormChange(newValue, param.id)}
                                />
                            );
                        case 'image':
                            return (
                                <ImageSingleFileUploadOrAssetComponent
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    variant="unstyled"
                                    style={{ marginTop: '15px' }}
                                    resize="vertical"
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onValueChange={(newValue) => handleFormChange(newValue, param.id)}
                                />
                            );
                        case 'pdf':
                            return (
                                <PDFSingleFileUploadOrAssetComponent
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    variant="unstyled"
                                    style={{ marginTop: '15px' }}
                                    resize="vertical"
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onValueChange={(newValue) => handleFormChange(newValue, param.id)}
                                />
                            );
                        case 'line':
                            return (
                                <TextInput
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    style={{ marginTop: '15px' }}
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onChange={(event) => handleFormChange(event.target.value, param.id)}
                                />
                            );
                        case 'list':
                            return (
                                <Select
                                    key={param.id}
                                    label={param.name}
                                    data={param.ui.lookup.split('|')}
                                    required
                                    placeholder={param.ui.placeholder}
                                    style={{ marginTop: '15px' }}
                                    id={param.id}
                                    checkIconPosition="right"
                                    filter={optionsFilter}
                                    searchable
                                    value={formValues[param.id]}
                                    onChange={(value) => handleFormChange(value, param.id)}
                                />
                            );
                        default:
                            return <div key={param.id}>Unknown parameter type</div>;
                    }
                }
                return null;
            })}
        </>
    );
};

export default ParameterForm;
