import {Button, Center, Group, LoadingOverlay, Modal, Stack, Text, Title} from "@mantine/core";
import StripeForm from "./StripeForm";
import React, {useState} from "react";
import apiService from "../services/ApiService";
import {useConfig} from "../ConfigContext";
import {useAuth} from "../AuthContext";


const PurchaseSubButton = ({subscriptionContext, plan, isMonthly, refreshInfo, setCheckoutIsVisible}: any) => {

    const { priceOrder } = useConfig();

    const {refreshProfile} = useAuth()

    const [checkoutOpen, setCheckoutOpen] = useState(false);
    const [cancelOpen, setCancelOpen] = useState(false);
    const [upgradeOpen, setUpgradeOpen] = useState(false);
    const [upgradeCost, setUpgradeCost] = useState(null);
    const [downgradeOpen, setDowngradeOpen] = useState(false);
    const [resumeOpen, setResumeOpen] = useState(false);

    const [isProcessingRequest, setIsProcessingRequest] = useState(false);

    function checkoutProduct(price_id: string) {
        setCheckoutOpen(true);
        setCheckoutIsVisible(true)
    }

    function price_index(price_id: string) {
        for (let i = 0; i < priceOrder.length; i++) {
            if (priceOrder[i] === price_id) {
                return i;
            }
        }

        return -1
    }

    function isUpgrade(price_id: string) {
        let current_price_index = price_index(subscriptionContext['current_price'])
        let new_price_index = price_index(price_id)

        return current_price_index < new_price_index
    }

    function modifyProduct(price_id: string) {

        if (isUpgrade(price_id)) {
            setUpgradeOpen(true)
            apiService.getSubscriptionUpgradeCost(price_id).then((res) => {
                setUpgradeCost(res['amount'])
            })
        }
        else {
            setDowngradeOpen(true)
        }

    }

    let newDate = new Date();
    newDate.setTime(subscriptionContext['period_end'] * 1000);
    let periodEndString = newDate.toUTCString();
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    let shortPeriodEndString = newDate.toLocaleDateString('en-US', options);

    const priceId = isMonthly ? plan.stripe_prices.monthly : plan.stripe_prices.annual
    const cost = isMonthly ? plan.price.monthly : plan.price.annual
    const newPlan = plan.name

    return <div>
        <Modal size="75%" title={<Text size="xl" fw={700}>Checkout</Text>}
               opened={checkoutOpen} onClose={() => {
            setCheckoutOpen(false);
            setCheckoutIsVisible(false)
        }}>
            <StripeForm id={priceId} isSubscription={true} refreshSubInfo={refreshInfo}/>
        </Modal>
        <Modal size="75%" title={<Text size="xl" fw={700}>Downgrade Subscription</Text>}
               opened={cancelOpen} onClose={() => {
            setCancelOpen(false);
        }}>
            {
                isProcessingRequest ?
                    <Text size={'lg'}>Processing downgrade. Please wait.</Text>
                    :
                    <Text size={'lg'}>Are you sure you want to downgrade to the free plan? If you do, at the end of the billing period ({periodEndString}).</Text>
            }
            <Button color="blue" fullWidth mt="md" radius="md" disabled={isProcessingRequest}
                onClick={() => {
                    setIsProcessingRequest(true)
                    apiService.cancelSubscription().then((id) => {
                        setCancelOpen(false)
                        setIsProcessingRequest(false)
                        refreshInfo()
                    })
                }}>
                    Downgrade
            </Button>
        </Modal>
        <Modal size="75%" title={<Text size="xl" fw={700}>Upgrade Subscription</Text>}
               opened={upgradeOpen} onClose={() => {
            setUpgradeOpen(false);
        }}>
            <LoadingOverlay visible={upgradeCost === null} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }}/>
            {
                isProcessingRequest ?
                    <Text size={'lg'}>Processing upgrade. Please wait.</Text>
                    :
                    <>
                        <Group>
                            <Title
                                size='h1'>${upgradeCost !== null ? (upgradeCost / 100).toFixed(2) : '(loading)'}</Title>
                            <Title size='h3'>for the first {isMonthly ? 'month' : 'year'}</Title>
                        </Group>
                        <Text size={'lg'}>Upgrading your account will cost ${upgradeCost !== null ? (upgradeCost / 100).toFixed(2) : '(loading)'} for the first {isMonthly ? 'month' : 'year'}. After that it will cost <b>${cost.toFixed(2)} every {isMonthly ? 'month' : 'year'}</b>. Are you sure?.</Text>
                    </>
            }
            <Button color="blue" fullWidth mt="md" radius="md" disabled={isProcessingRequest || upgradeCost === null} onClick={() => {
                setIsProcessingRequest(true)
                apiService.upgradeSubscription(priceId).then((id) => {
                    setUpgradeOpen(false)
                    setUpgradeCost(null);
                    setIsProcessingRequest(false)
                    refreshInfo()
                    refreshProfile()
                })
            }}>
                    Upgrade
            </Button>
        </Modal>
        <Modal size="75%" title={<Text size="xl" fw={700}>Downgrade Subscription</Text>}
               opened={downgradeOpen} onClose={() => {
            setDowngradeOpen(false);
        }}>
            {
                isProcessingRequest ?
                    <Text size={'lg'}>Processing downgrade. Please wait.</Text>
                    :
                    <Text size={'lg'}>Are you sure you want to downgrade? Your plan will be changed to {plan['name']} at the end of
                        the billing period ({periodEndString}).</Text>
            }
            <Button color="blue" fullWidth mt="md" radius="md" disabled={isProcessingRequest} onClick={() => {
                setIsProcessingRequest(true)
                apiService.downgradeSubscription(priceId).then((id) => {
                    setDowngradeOpen(false)
                    setIsProcessingRequest(false)
                    refreshInfo()
                })
            }}>
                    Downgrade
            </Button>
        </Modal>
        <Modal size="75%" title={<Text size="xl" fw={700}>Continue Subscription</Text>}
               opened={resumeOpen} onClose={() => {
            setResumeOpen(false);
        }}>
            {
                isProcessingRequest ?
                    <Text size={'lg'}>Processing. Please wait.</Text>
                    :
                    <Text size={'lg'}>Are you sure you want to continue with the {subscriptionContext?.current_plan} plan?</Text>
            }
            <Button color="blue" fullWidth mt="md" radius="md" disabled={isProcessingRequest} onClick={() => {
                setIsProcessingRequest(true)
                apiService.revertPendingSubscriptionChange().then((res) => {
                    setResumeOpen(false)
                    setIsProcessingRequest(false)
                    refreshInfo()
                })
            }}>
                    Continue {subscriptionContext?.current_plan} Plan
            </Button>
        </Modal>
        {
            plan.name.toLowerCase() == 'free' ?
                subscriptionContext['cancelled'] || subscriptionContext['current_plan'].toLowerCase() == 'free' ?
                    <></>
                    :
                    <Button fullWidth
                            variant='outline'
                            color='blue'
                            size="lg"
                            onClick={() => {
                                setCancelOpen(true)
                            }
                            }>
                        Downgrade
                    </Button>
                :
                priceId === subscriptionContext['current_price'] && priceId !== '' ?
                    subscriptionContext['cancelled'] || subscriptionContext['downgrading'] ?
                        <div>
                            <Center maw={400} style={{padding: '0px', margin: '0px'}}>
                                <Title size={'h3'} textWrap="balance" style={{padding: '0px', margin: '0px'}}>Current Plan</Title>
                            </Center>
                            <Center maw={400} style={{padding: '0px', margin: '0px'}}>
                                <Button h={40} variant="transparent" style={{padding: '0px', margin: '0px'}}
                                        onClick={() => {
                                            setResumeOpen(true)
                                        }}>
                                    <Title size={'h4'} style={{
                                        padding: '0px',
                                        margin: '0px'
                                    }} textWrap="wrap" >Downgrading {shortPeriodEndString}</Title>
                                </Button>
                            </Center>
                        </div>
                        :
                        <Center maw={400} h={50}>
                            <Title size={'h3'} textWrap="balance">Current Plan</Title>
                        </Center>
                    :
                    plan.available ?
                        subscriptionContext['active'] ?
                            subscriptionContext['next_price'] === priceId ?
                                <Button fullWidth
                                        variant='outline'
                                        color='blue'
                                        size="lg"
                                        disabled
                                >
                                    Future Downgrade
                                </Button>
                                :
                                <Button fullWidth
                                        variant='outline'
                                        color='blue'
                                        size="lg"
                                        onClick={() => {
                                            modifyProduct(priceId)
                                        }
                                        }>
                                    {isUpgrade(priceId) ? 'Upgrade' : 'Downgrade'}
                                </Button>
                            :
                            <Button fullWidth
                                    variant='outline'
                                    color='blue'
                                    size="lg"
                                    onClick={() => {
                                        checkoutProduct(priceId)
                                    }
                                    }>
                                Purchase
                            </Button>
                        :
                        <Button fullWidth
                                disabled={true}
                                variant={'filled'}
                                color={'gray'}
                                size="lg"
                                onClick={() => {

                                }
                                }>
                            Waitlist
                        </Button>
        }
    </div>
}

export default PurchaseSubButton