import React, { useState } from 'react';
import { Button, Tooltip } from '@mantine/core';
import { IconDownload, IconCheck } from '@tabler/icons-react';

function DownloadButton({ url, fileName, label = 'Download' }) {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = async () => {
        setIsDownloading(true);
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok.');

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName || url.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Failed to download the file:', error);
            setIsDownloading(false);  // Reset on error
        }
        setTimeout(() => setIsDownloading(false), 2000);  // Reset after 2 seconds
    };

    return (
            <Button onClick={handleDownload} variant="transparent" size="xs" color={isDownloading ? 'teal' : 'gray'}>
                {label} {/* Label text */}
                {isDownloading ? <IconCheck size={18} style={{ marginLeft: 8 }} /> : <IconDownload size={18} style={{ marginLeft: 8 }} />}
            </Button>
    );
}

export default DownloadButton;
