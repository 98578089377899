import React from 'react';
import { CopyButton, ActionIcon, Tooltip, rem } from '@mantine/core';
import { IconCopy, IconCheck } from '@tabler/icons-react';

function TextCopyButton({ text }) {
    return (
        <CopyButton value={text} timeout={2000}>
            {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} position="right" style={{padding: 5}}>
                    <ActionIcon size={"sm"} color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                        {copied ? (
                            <IconCheck />
                        ) : (
                            <IconCopy />
                        )}
                    </ActionIcon>
                </Tooltip>
            )}
        </CopyButton>
    );
}

export default TextCopyButton;
