// UseTaskModal.js
import React, {useState, useEffect} from "react";
import BasicModal from "./BasicModal";
import {
    Button, ScrollArea, Text, Loader,
    Box, Center, Accordion, Group, Space, Divider, useMantineTheme
} from "@mantine/core";
import {IconPlayerPlay} from "@tabler/icons-react";
import {_pq, _pq_resume} from "../polyqWidget.mjs";
import {useAuth} from "../AuthContext";
import {useConfig} from "../ConfigContext";
import ParameterForm from "./ParameterForm";
import ConfirmDialog from "./ConfirmDialog";
import StepIndicator from "./StepIndicator";
import ResultDisplay from "./ResultDisplay";

// Utility function
const generateAssetUrl = (assetId, isAuthenticated, apiBaseUrl) => {
    if (isAuthenticated) {
        return `${apiBaseUrl}/api/asset/get/${assetId}?token=${localStorage.getItem("sessionToken")}`;
    } else {
        return `${apiBaseUrl}/api/asset/get/${assetId}`;
    }
};

function UseTaskModal({modalOpened, setModalOpened, task, appId, showHistory = false}) {
    const [waiting, setWaiting] = useState(false);
    const [maxSteps, setMaxSteps] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [activeStatus, setActiveStatus] = useState("");
    const [currentSection, setCurrentSection] = useState('TASK');
    const [resultValue, setResultValue] = useState([]);
    const [missingValue, setMissingValue] = useState([]);
    const [data, setData] = useState({parameters: []});
    const {isAuthenticated} = useAuth();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [contentForClipboard, setContentForClipboard] = useState({});
    const [formValues, setFormValues] = useState({});
    const [missingFormValues, setMissingFormValues] = useState({});
    
    const {api} = useConfig()
    const theme = useMantineTheme()

    useEffect(() => {
        setData({...task, parameters: task.parameters || []});
    }, [task]);

    const resetModalData = () => {
        setFormValues({});
        setMissingValue({})
        setResultValue([]);
        setActiveStep(0);
        setMaxSteps(0);
        setActiveStatus("");
        setCurrentSection('TASK');
    };

    function handleInitialFormChange(value, fieldName) {
        setFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: value
        }));
    }

    function handleMissingFormChange(value, fieldName) {
        setMissingFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: value
        }));
    }

    const isAnyFieldEmpty = task.parameters && task.parameters.some(param => {
        if (param.ui) {
            return !formValues[param.id];
        }
        return false;
    });

    const isAnyMissingFieldEmpty = missingValue.parameters && missingValue.parameters.some(param => {
        if (param.ui) {
            return !missingFormValues[param.id];
        }
        return false;
    });

    const optionsFilter = ({options, search}) => {
        const splittedSearch = search.toLowerCase().trim().split(' ');
        return options.filter((option) => {
            const words = option.label.toLowerCase().trim().split(' ');
            return splittedSearch.every((searchWord) => words.some((word) => word.includes(searchWord)));
        });
    };

    function handleUseClick() {
        setWaiting(true);
        let parameters = {};
        for (const p of task['parameters']) {
            if (p['ui']) {
                parameters[p['name']] = {
                    'metadata': {'Content-Type': p['valueType']},
                    'value': formValues[p['id']] || ''
                };
            }
        }

        setResultValue([]);
        setCurrentSection('RESULT');
        _pq(
            api.baseUrl,
            parameters,
            task.id,
            appId,
            5000,
            (results, error, isError, missing = []) => {
                setWaiting(true);
                if (!isError) {
                    console.log("Results:\n" + JSON.stringify(results))
                    setResultValue(results == null ? [] : results);
                    setMissingValue(missing);
                    setWaiting(false);
                } else {
                    console.log("ERROR: " + error);
                    setWaiting(false);
                }
            },
            (body) => {
                setMaxSteps(body['max']);
                setActiveStep(body['step'] + 1);
                setActiveStatus(body['status']);
            },
            showHistory
        );
    }

    function handleAdditionalInputClick() {
        setWaiting(true);
        let parameters = [];
        for (const p of missingValue['parameters']) {
            let parameter = p['parameter']
            parameters.push({
                'assetId': p['assetId'],
                'value': missingFormValues[parameter['id']] || ''
            });
        }

        setResultValue([]);
        setCurrentSection('RESULT');
        _pq_resume(
            api.baseUrl,
            parameters,
            missingValue.requestId,
            appId,
            5000,
            (results, error, isError, missing = []) => {
                setWaiting(true);
                if (!isError) {
                    console.log("Results:\n" + JSON.stringify(results))
                    setResultValue(results == null ? [] : results);
                    setMissingValue(missing);
                    setWaiting(false);
                } else {
                    console.log("ERROR: " + error);
                    setWaiting(false);
                }
            },
            (body) => {
                setMaxSteps(body['max']);
                setActiveStep(body['step'] + 1);
                setActiveStatus(body['status']);
            },
            showHistory
        );

        setMissingValue({})
    }

    useEffect(() => {
        if (modalOpened) {
            resetModalData();
        }
    }, [modalOpened]);

    return (
        <>
            <BasicModal
                opened={modalOpened}
                onClose={() => setIsConfirmOpen(true)}
                title={task.name}
                padding={0}
                size="lg"
                searchable={ScrollArea.Autosize}
                styles={{
                    modal: {
                        display: "flex",
                        flexDirection: "column",
                        radius: "lg",
                        height: "80vh",
                        overflow: "hidden",
                    },
                }}
            >
                <Accordion value={currentSection} onChange={setCurrentSection} style={{paddingTop: "0px"}}
                           variant="contained" defaultValue="TASK">
                    <Accordion.Item key="TASK" value="TASK">
                        <Accordion.Control>
                            <svg style={{paddingTop: 10}} xmlns="http://www.w3.org/2000/svg"
                                 className="icon icon-tabler icon-tabler-help-hexagon" width="50"
                                 height="50"
                                 viewBox="0 0 24 24" strokeWidth="2.0" stroke="#7DA1E860" fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path
                                    d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"/>
                                <path d="M12 16v.01"/>
                                <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"/>
                            </svg>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <ScrollArea style={{height: 500}}>
                                <ParameterForm
                                    parameters={task.parameters}
                                    formValues={formValues}
                                    handleFormChange={handleInitialFormChange}
                                    optionsFilter={optionsFilter}
                                />
                                <Box style={{paddingTop: '25px'}}>
                                    <Center>
                                        <Button
                                            display={task ? "block" : "none"}
                                            disabled={waiting || isAnyFieldEmpty}
                                            onClick={handleUseClick}
                                        >
                                            {waiting ? <Loader size={20} color="#ccc"/> : <IconPlayerPlay/>}
                                            <div style={{paddingLeft: "10px"}}>{task.name}</div>
                                        </Button>
                                    </Center>
                                </Box>
                            </ScrollArea>
                        </Accordion.Panel>
                    </Accordion.Item>
                    {
                        (waiting || (resultValue && resultValue.length > 0) || (missingValue && missingValue.parameters && missingValue.parameters.length > 0)) ?
                            <Accordion.Item key="RESULT" value="RESULT">
                                <Accordion.Control>
                                    <svg style={{paddingTop: 10}} xmlns="http://www.w3.org/2000/svg"
                                         className="icon icon-tabler icon-tabler-help-hexagon" width="50"
                                         height="50"
                                         viewBox="0 0 24 24" strokeWidth="2.0" stroke="#7DA1E860" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"/>
                                        <path d="M2 13.5v5.5l5 3"/>
                                        <path d="M7 16.545l5 -3.03"/>
                                        <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"/>
                                        <path d="M12 19l5 3"/>
                                        <path d="M17 16.5l5 -3"/>
                                        <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5"/>
                                        <path d="M7 5.03v5.455"/>
                                        <path d="M12 8l5 -3"/>
                                    </svg>
                                </Accordion.Control>
                                <ScrollArea scrollbars="y" scrollbarSize={10}>
                                    <Accordion.Panel>
                                        <StepIndicator
                                            activeStep={activeStep}
                                            maxSteps={maxSteps}
                                            activeStatus={activeStatus}
                                            configJson={{primaryColor: theme.colors.brand[6]}}
                                        />
                                        {activeStatus === 'ERROR' && (
                                            <Group justify="center" gap="xl">
                                                <Button variant="light" color={theme.colors.brand[6]}>Retry</Button>
                                            </Group>
                                        )}
                                        {activeStatus === 'ABORTED' && (
                                            <Group justify="center" gap="xl">
                                                <Button variant="light" color={theme.colors.brand[6]}>Close</Button>
                                            </Group>
                                        )}
                                        {resultValue.sort((a, b) => {
                                            const dateA = new Date(a.parameter.metadata.created);
                                            const dateB = new Date(b.parameter.metadata.created);
                                            return dateB - dateA;
                                        }).map(result => (
                                            <ResultDisplay
                                                key={result.parameter.name}
                                                result={result}
                                                generateAssetUrl={(assetId) => generateAssetUrl(assetId, isAuthenticated, api.baseUrl)}
                                                setContentForClipboard={setContentForClipboard}
                                                contentForClipboard={contentForClipboard}
                                            />
                                        ))}
                                        {(missingValue && missingValue.parameters && missingValue.parameters.length > 0) ?
                                            <>
                                                <Space h={25} />
                                                <Text size="xs">Please provide additional input below</Text>
                                                <Divider my="sm" />
                                                <Space h={15} />
                                                <ScrollArea style={{height: 500}}>
                                                    <ParameterForm
                                                        parameters={missingValue.parameters.map(mv => mv.parameter)}
                                                        formValues={missingFormValues}
                                                        handleFormChange={handleMissingFormChange}
                                                        optionsFilter={optionsFilter}
                                                    />
                                                    <Box style={{paddingTop: '25px'}}>
                                                        <Center>
                                                            <Button
                                                                display={task ? "block" : "none"}
                                                                disabled={waiting || isAnyMissingFieldEmpty}
                                                                onClick={handleAdditionalInputClick}
                                                            >
                                                                {waiting ? <Loader size={20} color="#ccc"/> :
                                                                    <IconPlayerPlay/>}
                                                                <div style={{paddingLeft: "10px"}}>Continue</div>
                                                            </Button>
                                                        </Center>
                                                    </Box>
                                                </ScrollArea>
                                            </>
                                            : <></>
                                        }
                                        <Text fw={700} size="xs">Disclaimer:</Text>
                                        <Text size="xs">
                                            The content, results, and information provided by this service are
                                            generated through automated processes and do not reflect the views, beliefs,
                                            or opinions of PolyQ.ai Inc. PolyQ.ai Inc. makes no representations or
                                            warranties of any kind, express or implied, about the completeness,
                                            accuracy, reliability, suitability, or availability with respect to the
                                            content or information produced by this service for any purpose. Any
                                            reliance you place on such content is therefore strictly at your own risk.
                                            In no event will PolyQ.ai Inc. be liable for any loss or damage including
                                            without limitation, indirect or consequential loss or damage, or any loss or
                                            damage whatsoever arising from loss of data or profits arising out of, or in
                                            connection with, the use of this service. Users are advised to verify and
                                            consider the content and results before making any decision based on them.
                                        </Text>
                                    </Accordion.Panel>
                                </ScrollArea>
                            </Accordion.Item>
                            :
                            <></>
                    }
                </Accordion>
            </BasicModal>
            <ConfirmDialog
                opened={isConfirmOpen}
                onConfirm={() => {
                    setModalOpened(false);
                    setIsConfirmOpen(false);
                }}
                onCancel={() => setIsConfirmOpen(false)}
                message="Please confirm that all input values and generated results will be lost."
            />
        </>
    );
}

export default UseTaskModal;
