import axios from "axios";
import {config} from "../portalConfig";

const axiosInstance = axios.create({
    baseURL: config.api.baseUrl, // Use the base URL from the config
    headers: {
        "Content-Type": "application/json",
    },
});

export default axiosInstance;
