import React, {useCallback, useEffect, useState} from 'react';
import {
    IconThumbUp,
    IconThumbUpFilled,
    IconThumbDown,
    IconThumbDownFilled,
    IconLoader, IconSend2
} from '@tabler/icons-react';
import {Input, Text} from '@mantine/core';
//import {addFeedback} from "../services/apiService";

const FeedbackComponent = ({item}) => {
    const [rating, setRating] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [feedbackId, setFeedbackId] = useState(null);

    const addFeedback = async (param1, param2) => {

    }

    const handleThumbClick = (type) => {
        setRating(type);
        setShowInput(true)
    };

    const handleInputChange = (event) => {
        setComment(event.target.value);
    };

    const handleFeedbackClick = useCallback(async () => {
        setLoading(true);
        try {
            console.info("item.data: " + JSON.stringify(item.data));
            const feedback = {};
            if (feedbackId) {
                feedback['id'] = feedbackId;
            }

            if (comment) {
                feedback['comment'] = comment;
            }

            if (rating) {
                feedback['rating'] = rating;
            }

            const response = await addFeedback(item.data, feedback);
            setFeedbackId(response.feedbackId); // Ensuring feedbackId is used after being defined
            setLoading(false);
            if (comment && rating) {
                setSaved(true);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error saving feedback:', error);
        }
    }, [feedbackId, comment, rating, item.data]);

    useEffect(() => {
        if (rating !== null) {
            handleFeedbackClick();
        }
    }, [rating]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                {!showInput && !saved && (
                    <Text size="xs" c='#7DA1E8' style={{paddingBottom: 10, flex: 1}}>
                        Source: {item.source.split("_").join(" ")}
                    </Text>
                )}
                {showInput || saved ? (
                    <Input
                        size="xs"
                        radius="xl"
                        variant={saved ? "unstyled" : "default"}
                        placeholder="Additional feedback"
                        value={comment}
                        onChange={handleInputChange}
                        style={{flex: 1, backgroundColor: saved ? 'transparent' : 'white'}}
                        readOnly={saved}
                    />
                ) : null}
                <div style={{display: 'flex', alignItems: 'center', color: '#7DA1E8', marginLeft: '10px'}}>
                    {showInput ? (
                        loading ? (
                            <IconLoader style={{cursor: 'pointer', marginRight: '10px'}}/>
                        ) : saved ? (
                            rating === 'up' ?
                                <IconThumbUpFilled style={{cursor: 'pointer', marginRight: '10px'}}/>
                                :
                                <IconThumbDownFilled style={{cursor: 'pointer', marginRight: '10px'}}/>
                        ) : (
                            <IconSend2
                                style={{cursor: 'pointer', marginRight: '10px'}}
                                onClick={handleFeedbackClick}
                            />
                        )
                    ) : (
                        <>
                            <IconThumbUp
                                style={{cursor: 'pointer', marginRight: '10px'}}
                                onClick={() => handleThumbClick('up')}
                            />
                            <IconThumbDown
                                style={{cursor: 'pointer'}}
                                onClick={() => handleThumbClick('down')}
                            />
                        </>
                    )}
                </div>
            </div>
            {(showInput || saved) && (
                <Text size="xs" c='#7DA1E8' style={{marginTop: 10, textAlign: 'center'}}>
                    Source: {item.source.split("_").join(" ")}
                </Text>
            )}
        </div>
    );
};

export default FeedbackComponent;
