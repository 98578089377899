import React, {useState, useEffect} from 'react';
import {Center, Loader} from "@mantine/core";

interface PQWidgetProps {
    apiKey: string;
    taskId: string;
    completion?: (results: any, error: Error | null, isError: boolean) => void;
    statusUpdate?: (status: any) => void;
    useButtonLabel?: string;
    style?: {
        input?: {
            box?: React.CSSProperties;
            params?: React.CSSProperties;
            param?: React.CSSProperties;
            button?: React.CSSProperties;
        };
        results?: {
            box?: React.CSSProperties;
            scroll?: React.CSSProperties;
        };
    };
}

declare global {
    interface Window {
        $pq?: {
            init: (containerId: string, apiKey: string, taskId: string, completion?: Function, statusUpdate?: Function, useButtonLabel?: string, style?: object) => void;
        };
    }
}

const PQWidget: React.FC<PQWidgetProps> = ({apiKey, taskId, completion, statusUpdate, useButtonLabel, style}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const loadResources = async () => {
            try {
                const cssLink = await loadCSS('https://pq-widget.s3.amazonaws.com/index.css');
                console.log('pq-widget CSS loaded successfully', cssLink);
                const jsScript = await loadScript('https://pq-widget.s3.amazonaws.com/index.js');
                console.log('pq-widget library loaded successfully', jsScript);
                initializePQ();
                setIsLoading(false);
            } catch (err) {
                console.error('Failed to load resources:', err);
                setIsLoading(false);
            }
        };

        loadResources();
    }, []);

    const loadScript = (src: string): Promise<HTMLScriptElement> => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = () => resolve(script);
            script.onerror = () => reject(new Error(`Failed to load script ${src}`));
            document.head.appendChild(script);
        });
    };

    const loadCSS = (href: string): Promise<HTMLLinkElement> => {
        return new Promise((resolve, reject) => {
            const link = document.createElement('link');
            link.href = href;
            link.rel = 'stylesheet';
            link.onload = () => resolve(link);
            link.onerror = () => reject(new Error(`Failed to load CSS ${href}`));
            document.head.appendChild(link);
        });
    };

    function initializePQ(): void {
        if (window.$pq) {
            console.log("Initializing $pq in pq-container with apiKey:", apiKey, "taskId:", taskId);
            window.$pq.init('pq-container', apiKey, taskId, completion, statusUpdate, useButtonLabel, style);
        }
    }

    return (
        <div>
            {isLoading ? (
                <Center>
                    <Loader type="dots"/>
                </Center>
            ) : (
                <></>
            )}
            <div id="pq-container"/>
        </div>
    );
}

export default PQWidget;
