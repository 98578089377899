import React, {useState, useEffect, useContext, useCallback} from "react";
import {
    Card,
    Text,
    Loader,
    Badge,
    Image,
    Button,
    Flex,
    ScrollArea,
    Tabs,
    Space,
    Modal, rgba, useMantineTheme,
} from "@mantine/core";
import ApiService from "../services/ApiService";
import { useConfig } from '../ConfigContext';

//import {AppContext} from "../AppContext";
import apiService from "../services/ApiService";
import CategoryTasks from "../components/CategoryTasks";

const TasksPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    //const {data, setData} = useContext(AppContext);
    const [categoriesCopy, setCategories] = useState();
    const [activeTab, setActiveTab] = useState(null);

    const [tasks, setTasks] = useState([])

    const {categories} = useConfig()
    const theme = useMantineTheme()
    

    useEffect(() => {

        const loadTasks = async () => {
            setIsLoading(true);
            try {
                //await fetchTasks(setData);
                await apiService.fetchTasks(setTasks)
            } catch (error) {
                console.error("Error fetching tasks:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadTasks();
    }, []);

    useEffect(() => {
        const localTaskCategories = {};
        if (tasks) {
            tasks.forEach((task) => {
                try {
                    if (task["category"]) {
                        task.category.toLowerCase().split('|').forEach((category) => {
                            if (!localTaskCategories[category]) {
                                localTaskCategories[category] = [];
                            }
                            // console.log("Task: " + task["name"] + " with category: " + task["category"])
                            localTaskCategories[category].push(task);
                        });
                    }
                    // const category = taskData.category || "uncategorized";

                } catch (error) {
                    // Handle parsing error
                    const uncategorized = "uncategorized";
                    if (!localTaskCategories[uncategorized]) {
                        localTaskCategories[uncategorized] = [];
                    }
                    localTaskCategories[uncategorized].push(task);
                }
            });
        }
        setCategories(localTaskCategories);
    }, [tasks]);

    // Container styles for centering the grid
    const gridContainerStyle = {
        maxWidth: "1300px", // This should match the maximum width of your header
        margin: "auto", // Center the grid container
        padding: "20px", // Add padding
    };

    const categoryCardStyle = {
        height: "256px", // Fixed height for all cards
        width: "256px", // Fixed width for all cards
        // backgroundColor: "#fff",
        // color: theme.primaryColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        cursor: 'pointer'
    };

    return (
        <div style={gridContainerStyle}>
            {isLoading ? (
                <div style={{justifyContent: "center", display: "flex"}}>
                    <Loader color={theme.colors.brand[6]}/>
                </div>
            ) : (
                <>
                    {!activeTab && (
                        <Flex
                            mih={50}
                            gap="xl"
                            justify="center"
                            align="center"
                            direction="row"
                            wrap="wrap"
                        >
                            {categories.map((category, index) => (
                                <Card
                                    radius="lg"
                                    key={index}
                                    shadow="sm"
                                    padding="sm"
                                    style={categoryCardStyle}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.border = "2px solid " + theme.colors.brand[6]
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.border = "2px solid " + theme.colors.brand[6] + "60"
                                    }}
                                    onClick={() => setActiveTab(category.name)}
                                >
                                    <Image
                                        src={category.imageName}
                                        alt="Icon"
                                        w={96}
                                        h={96}
                                    />
                                    <Text size="lg" weight={500} lineClamp={1}>
                                        {category.title}
                                    </Text>
                                    <Text
                                        size="xs"
                                        lineClamp={6}
                                        style={{overflow: "hidden", padding: "15px"}}
                                    >
                                        {category.description}
                                    </Text>
                                </Card>
                            ))}
                        </Flex>
                    )}
                    {activeTab && (
                        <Tabs
                            defaultValue={activeTab}
                            active={activeTab}
                            onChange={setActiveTab}
                            variant="pills"
                            radius="lg"
                            styles={{
                                tabLabel: {
                                    color: theme.colors.brand[6],
                                },
                                tab: {
                                    padding: "6px 18px",
                                    fontSize: "1.0rem",
                                    marginRight: "9px",
                                    marginLeft: "9px",
                                    // color: !activeTab? "#fff" : theme.primaryColor
                                },
                            }}
                        >
                            <Tabs.List justify="center">
                                {categories.map((category, index) => (
                                    <Tabs.Tab
                                        key={category.name}
                                        value={category.name}
                                        color={theme.colors.brand[6] + "30"}
                                    >
                                        {activeTab && (
                                            <div
                                                onClick={() => setActiveTab(category.name)}
                                                onMouseOver={
                                                    (e) => {
                                                        if (activeTab === e.currentTarget.innerText) {
                                                            e.currentTarget.style.color = "#fff";
                                                        }
                                                    }
                                                }
                                                onMouseOut={
                                                    (e) => {
                                                        if (activeTab === e.currentTarget.innerText) {
                                                            e.currentTarget.style.color = theme.colors.brand[6]
                                                        }
                                                    }
                                                }
                                            >
                                                {category.title}
                                            </div>
                                        )}
                                    </Tabs.Tab>
                                ))}
                            </Tabs.List>
                            <Space h="xl"/>
                            {Object.keys(categoriesCopy).map((category) => (
                                <Tabs.Panel value={category} key={category}>
                                    <CategoryTasks tasks={categoriesCopy[category]}/>
                                </Tabs.Panel>
                            ))}
                        </Tabs>
                    )}
                </>
            )}
        </div>
    );
};

export default TasksPage;
