import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Text,
    Grid,
    SimpleGrid,
    Card,
    Image,
    Group,
    Container,
    Badge,
    TextInput,
    List
} from '@mantine/core';
import { useConfig, NewsItem } from '../ConfigContext';
import classes from './News.module.css';

function News() {
    const { newsCategories, news, authors } = useConfig();
    const authorLookup = Object.fromEntries(authors.map(author => [author.name, author]));
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredNews, setFilteredNews] = useState(news);
    const [selectedNewsItem, setSelectedNewsItem] = useState<NewsItem | null>(null);
    const { assetId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (assetId) {
            const newsItem = news.find(item => item.assetId === assetId);
            if (newsItem) {
                setSelectedNewsItem(newsItem);
            } else {
                navigate('/news');
            }
        } else {
            setSelectedNewsItem(null);
        }
    }, [assetId, news, navigate]);

    useEffect(() => {
        const lowercaseFilter = searchQuery.toLowerCase();
        const filteredBySearch = news.filter(item =>
            item.title.toLowerCase().includes(lowercaseFilter) ||
            item.abstract?.toLowerCase().includes(lowercaseFilter) ||
            (item.author && item.author.toLowerCase().includes(lowercaseFilter)) ||
            item.categoryId.toLowerCase().includes(lowercaseFilter)
        );

        const filteredByCategory = selectedCategory
            ? filteredBySearch.filter(item => item.categoryId === selectedCategory)
            : filteredBySearch;

        setFilteredNews(filteredByCategory);
    }, [searchQuery, selectedCategory, news]);

    const handleSelectNewsItem = (item: NewsItem) => {
        navigate(`/news/${item.assetId}`);
    };

    const renderNewsItem = (item: NewsItem) => (
        <Card withBorder radius="md" className={classes.card} key={item.assetId}
              onClick={() => handleSelectNewsItem(item)}>
            <Card.Section>
                <Image src={item.image || "https://i.imgur.com/Cij5vdL.png"} height={180}/>
            </Card.Section>
            <Text className={classes.title} fw={500} component="a" style={{marginTop: 10}}>
                {item.title}
            </Text>
            <Text fz="sm" c="dimmed" lineClamp={4}>
                {item.abstract}
            </Text>
        </Card>
    );

    const renderNewsItemKeyPoints = (item: NewsItem) => (
        <List
            spacing={"xs"}
            size={"sm"}
            style={{padding: 25}}
        >
            {item.keyPoints?.map((point, idx) =>
                <List.Item key={idx}>
                    <Text
                        fw={600}
                        className={classes.keyPoints}
                        style={{ display: 'inline', marginRight: '3px' }}
                    >
                        {point.split('|')[0]}
                    </Text>
                    <Text
                        fw={300}
                        className={classes.keyPoints}
                        style={{ display: 'inline' }}
                    >
                        {point.split('|')[1]}
                    </Text>
                </List.Item>)}
        </List>
    );

    return (
        <Container py="xl" size="xl">
            <TextInput
                radius={"xl"}
                placeholder="Search articles by name, author, or category"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.currentTarget.value)}
                style={{marginBottom: '20px'}}
            />
            <Group style={{justifyContent: 'center', margin: '10px 0'}}>
                {Object.keys(newsCategories).map((category) => (
                    <Badge
                        key={category}
                        color={selectedCategory === category ? 'blue' : 'gray'}
                        onClick={() => setSelectedCategory(category)}
                        style={{cursor: 'pointer'}}
                    >
                        {newsCategories[category].title}
                    </Badge>
                ))}
                <Badge color="red" onClick={() => setSelectedCategory('')} style={{cursor: 'pointer'}}>
                    Clear Filter
                </Badge>
            </Group>
            <Grid>
                {selectedNewsItem ? (
                    <>
                        <Grid.Col span={9}>
                            <Card withBorder radius="md" style={{padding: '20px'}}>
                                <Text size="xl" w={900} className={classes.title}>{selectedNewsItem.title}</Text>
                                <Text size="xs" w={300} className={classes.date}>{selectedNewsItem.date}</Text>
                                <Image src={selectedNewsItem.image || "https://i.imgur.com/Cij5vdL.png"} height={360} className={classes.image}/>
                                <Text size="md" className={classes.intro}>{selectedNewsItem.intro}</Text>
                                {renderNewsItemKeyPoints(selectedNewsItem)}
                                <Text size="md" className={classes.cta}>{selectedNewsItem.cta}</Text>
                                <Text size="md" className={classes.conclusion}>{selectedNewsItem.conclusion}</Text>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <SimpleGrid cols={1}>
                                {filteredNews.map(renderNewsItem)}
                            </SimpleGrid>
                        </Grid.Col>
                    </>
                ) : (
                    <Grid.Col span={12}>
                        <SimpleGrid cols={3}>
                            {filteredNews.map(renderNewsItem)}
                        </SimpleGrid>
                    </Grid.Col>
                )}
            </Grid>
        </Container>
    );
}

export default News;
