import React from 'react';
import { Modal, Button, Text, Group } from '@mantine/core';

function ConfirmDialog({ opened, onConfirm, onCancel, message }) {
    return (
        <Modal
            opened={opened}
            onClose={onCancel}
            title="Are you sure?"
            radius="lg"
            size="sm"
            centered
        >
            <Text size="sm">{message}</Text>
            <Group position="center" spacing="md" mt="md" justify="space-between">
                <Button onClick={onCancel}>No</Button>
                <Button variant="outline" color="red" onClick={onConfirm}>Yes</Button>
            </Group>
        </Modal>
    );
}

export default ConfirmDialog;
