import {
    Container,
    Title,
    Accordion,
    useMantineTheme,
    Group,
    Paper,
    Stack, Center
} from '@mantine/core';
import classes from './FAQ.module.css';
import {useConfig} from "../ConfigContext";
import React from "react";
import PQWidget from "../components/PQWidget";

export function FAQ() {
    const theme = useMantineTheme();
    const {faqs} = useConfig();

    const items = faqs.map((faq) => (
        <Accordion.Item key={faq.question} className={classes.item} value={faq.question}>
            <Accordion.Control>
                <Group>
                    {faq.icon ? React.createElement(faq.icon, {
                        stroke: 1.5,
                        color: theme.colors.accent[0]
                    }) : <></>} {faq.question}
                </Group>
            </Accordion.Control>
            <Accordion.Panel>{faq.answer}</Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <Stack>
            <Title ta="center" c={theme.colors.brand[6]} className={classes.title}>
                Frequently Asked Questions
            </Title>
            <Group grow align="start">
                <Container className={classes.wrapper}>
                    <Paper
                        radius={"md"}
                        style={{
                            borderWidth: 1,
                            borderColor: theme.colors.brand[6],
                            borderStyle: 'solid',
                            padding: 15
                        }}>
                        <Center><Title>Ask PolyQ's Digital Advisor</Title></Center>
                        <PQWidget
                            apiKey={"6dbeb7bb-9830-4fa4-8193-ec7d4974edab"}
                            taskId={"4bc0dd10-1071-4590-a5f0-960051a128b6"}
                            useButtonLabel="Search"
                            style={
                                {
                                    input: {
                                        box: {
                                            display: 'flex',      // Set display to flex to use flexbox layout
                                            flexDirection: 'row', // Align children in a row
                                            justifyContent: 'space-between', // Space out the children as needed
                                            alignItems: 'flex-end', // Align items at the start of the flex container
                                        },
                                        params: {
                                            flexGrow: 1,
                                            marginRight: '8px',
                                        },
                                        param: {
                                            borderRadius: "50px"
                                        },
                                        button: {
                                            backgroundColor: theme.colors.accent[4],
                                            borderRadius: "50px"
                                        }
                                    },
                                    results: {
                                        box: {
                                            marginTop: 25
                                        }
                                    }
                                }
                            }
                        />
                    </Paper>
                </Container>
                <Container size="xl" className={classes.wrapper}>
                    <Accordion variant="separated">
                        {items}
                    </Accordion>
                </Container>
            </Group>
        </Stack>
    );
}
