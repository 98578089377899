import React, {useCallback, useState, useEffect} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
    Button, Menu, Switch,
    Text
} from '@mantine/core'
import ApiService from "../services/ApiService";
import apiService from "../services/ApiService";
import {useAuth} from "../AuthContext";
import Divider = Menu.Divider;
import {Simulate} from "react-dom/test-utils";
import change = Simulate.change;

const stripePromise = loadStripe("pk_live_51PlDn4B3m9sHlAejMbqtaLjuYjg9yfp4QiM0QtenrjfCuOoWp788mLjb7uuKO1qxkX8FPMIzifeKMwPeXCtEtpC900gDFJsuoV");

const StripePaymentForm = ({changeDefaultMethod, changeSubscriptionMethod, checkoutCompleted = () => {}}: any) => {

    const fetchClientSecret = useCallback(() => {
        console.log(">> " + changeDefaultMethod + " : " + changeSubscriptionMethod)
        // Create a Checkout Session
        return ApiService.modifyPaymentMethod(changeDefaultMethod, changeSubscriptionMethod)
            .then((data) => data);
    }, [changeDefaultMethod, changeSubscriptionMethod]);

    const onComplete = useCallback(() => {
        checkoutCompleted()
    }, []);

    const options = {fetchClientSecret, onComplete};

    return (
        <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
        >
            <EmbeddedCheckout/>
        </EmbeddedCheckoutProvider>
    )
}

export default StripePaymentForm;