import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MantineProvider, createTheme} from '@mantine/core';
import {BrowserRouter} from 'react-router-dom';
import '@mantine/core/styles/global.css';

const theme = createTheme({
    breakpoints: {
        xs: '30em', // 480px
        sm: '48em', // 768px
        md: '64em', // 1024px
        lg: '74em', // 1184px
        xl: '90em'  // 1440px
    },
    colors: {
        brand: [
            '#E9EFF7',
            '#D6E0F0',
            '#C2D1E9',
            '#AFC2E2',
            '#9BB3DB',
            '#7DA1E8',
            '#6590D0',
            '#527FC8',
            '#3F6EBF',
            '#2C5DB7',
        ],
        gray: [
            '#FAFAFA', // gray[0] - lightest
            '#F0F1F2',
            '#E6E7E8',
            '#DCDDDE',
            '#D2D3D4',
            '#E3E4E6', // gray[5] - base color
            '#C5C6C8',
            '#A7A9AB',
            '#898B8D',
            '#6B6D6F', // gray[9] - darkest
        ],
        accent: [
            '#d8ba8e', // accent[0] - lightest
            '#c2a270', // accent[1]
            '#ad8a53', // accent[2]
            '#976f35', // accent[3]
            '#825e17', // accent[4] - base color
            '#704f12', // accent[5]
            '#5f410e', // accent[6]
            '#4e330a', // accent[7]
            '#3d2606', // accent[8]
            '#2c1802', // accent[9] - darkest
        ]
    }
});


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <MantineProvider theme={theme} defaultColorScheme="dark">
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </MantineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
