import React, {useEffect, useState} from 'react';
import {
    Card,
    Group,
    Text,
    useMantineTheme,
    Stack,
    SimpleGrid,
    SegmentedControl,
    Button,
    Title,
    Tooltip, Box, LoadingOverlay, Center, Space,
} from '@mantine/core';

import {useConfig} from '../ConfigContext';
import {useAuth} from "../AuthContext";
import ApiService from "../services/ApiService";
import PurchaseSubButton from "../components/PurchaseSubscriptionButton";

interface FeatureDefinition {
    available: boolean;
    description: string;
}

function PricingOptions() {
    const {plans, allFeatures} = useConfig();
    const {isAuthenticated, profile} = useAuth();
    const [isAnnual, setIsAnnual] = useState<boolean>(false);
    const theme = useMantineTheme();

    const [subscriptionStatus, setSubscriptionStatus] = useState(null)
    const [subscriptionUpdating, setSubscriptionUpdating] = useState<boolean>(false)
    const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false)

    useEffect(() => {
        refreshInfo()
    }, []);

    const refreshInfo = () => {
        if (subscriptionStatus) {
            retryRefreshSubscription().then(() => {
            })
        } else {
            ApiService.getSubscriptionStatus().then((result) => {
                setSubscriptionStatus(result);
            })
        }
    }

    const billingInterval = () => {
        if (subscriptionStatus == null) {
            return ''
        }
        let interval_name = ''
        if (subscriptionStatus['billing_cycle'] == 'month') {
            interval_name = ' with monthly billing'
        } else if (subscriptionStatus['billing_cycle'] == 'year') {
            interval_name = ' with yearly billing'
        }
        return interval_name
    }

    const featureLookup: Record<string, FeatureDefinition> = allFeatures.reduce((dict, feature) => {
        const {name, available = false, description = ''} = feature;
        dict[name] = {available, description};
        return dict;
    }, {} as Record<string, FeatureDefinition>);

    const getFeatureDescription = (featureName: string): string => {
        return featureLookup[featureName]?.description || '';
    };

    const styles = {
        wrapper: {
            padding: `calc(var(--mantine-spacing-sm) * 2) var(--mantine-spacing-sm)`,
        },
        planCard: (favorite: boolean) => ({
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: theme.shadows.xl,
            },
            padding: 'var(--mantine-spacing-md)',
            borderRadius: 'var(--mantine-radius-lg)',
            backgroundColor: theme.colors.accent[0] + "30",
            position: 'relative' as 'relative',
            ...(favorite && {
                backgroundColor: theme.colors.brand[0] + "30",
                borderColor: theme.colors.brand[6],
                boxShadow: theme.shadows.lg,
            }),
        }),
        title: {
            fontFamily: `Poppins, var(--mantine-font-family)`,
            fontSize: '32px',
            fontWeight: 600,
            lineHeight: 1.3,
            marginBottom: 'var(--mantine-spacing-xs)',
            color: theme.colors.brand[6],
            textAlign: 'center' as 'center',
        },
        price: {
            fontFamily: `Greycliff CF, var(--mantine-font-family)`,
            fontSize: '50px', // Reduced the font size by 50% (from 100px to 50px)
            fontWeight: 700,
            color: theme.colors.brand[6],
            textAlign: 'center' as 'center',
            marginBottom: 'var(--mantine-spacing-xs)', // Reduced bottom margin
        },
        priceDetails: {
            fontSize: '20px', // Smaller font for details under the price
            textAlign: 'center' as 'center',
            color: theme.colors.gray[7],
        },
        priceSmallDetails: {
            fontSize: '13px', // Smaller font for details under the price
            textAlign: 'center' as 'center',
            color: theme.colors.gray[7],
        },
        featureList: {
            paddingLeft: '0',
            margin: '5px 0',
            listStyleType: 'none',
        },
        featureItem: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 'var(--mantine-spacing-xs)',
            fontFamily: `Greycliff CF, var(--mantine-font-family)`,
            fontSize: '14px',
            fontWeight: 500,
            color: theme.colors.gray[7],
        },
        featureSpacer: {
            marginTop: 'var(--mantine-spacing-xl)', // Add extra space after the feature list
            marginBottom: 'var(--mantine-spacing-xl)', // Add extra space after the feature list
        },
        switchWrapper: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 'var(--mantine-spacing-lg)',
        },
        buttonWrapper: {
            position: 'absolute' as 'absolute',
            bottom: '16px',
            width: 'calc(100% - 32px)',
        },
    };

    const toggleBillingPeriod = (value: string) => {
        setIsAnnual(value === 'annual');
    };

    function convertDate(timestamp: number) {
        let date = new Date()
        date.setTime(timestamp * 1000)
        return date.toUTCString()
    }

    const retryRefreshSubscription = async () => {
        if (isAuthenticated) {
            setSubscriptionUpdating(true)

            let isUpdating = true
            let subscriptionData = subscriptionStatus
            let attempts = 0

            while (isUpdating && attempts < 15) {
                try {
                    subscriptionData = await ApiService.getSubscriptionStatus()
                    if (subscriptionStatus && subscriptionData) {
                        isUpdating = subscriptionStatus['current_plan'] === subscriptionData['current_plan'] &&
                            subscriptionStatus['current_price'] === subscriptionData['current_price'] &&
                            subscriptionStatus['cancelled'] === subscriptionData['cancelled'] &&
                            subscriptionStatus['downgrading'] === subscriptionData['downgrading'] &&
                            subscriptionStatus['next_plan'] === subscriptionData['next_plan'] &&
                            subscriptionStatus['next_price'] === subscriptionData['next_price']
                    } else {
                        isUpdating = false;
                    }

                    if (isUpdating) {
                        console.log("Data is still updating, fetching again...");
                        // Wait for a specified time before the next fetch
                        await new Promise((resolve) => setTimeout(resolve, Math.ceil((Math.pow(1.25, attempts) - 1) * 100))); // (1.5^x - 1) * 0.12s
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Optional: Decide whether to break or continue on error
                }

                attempts++
            }

            setSubscriptionStatus(subscriptionData)
            setSubscriptionUpdating(false)

            return isUpdating
        }

        return true
    }

    return (
        <Box pos="relative">
            <LoadingOverlay visible={subscriptionUpdating && !checkoutOpen} zIndex={1000}
                            overlayProps={{radius: "sm", blur: 2}}/>
            <div style={styles.wrapper}>
                <div style={styles.switchWrapper}>
                    <SegmentedControl
                        size="lg"
                        value={isAnnual ? 'annual' : 'monthly'}
                        onChange={toggleBillingPeriod}
                        data={[
                            {label: 'Monthly', value: 'monthly'},
                            {label: 'Annual', value: 'annual'},
                        ]}
                    />
                </div>
                <Center>
                    {
                        subscriptionStatus && (subscriptionStatus['cancelled'] || subscriptionStatus['downgrading']) ?
                            <>
                                <Text size='xl' fw={300}>At the end of the billing period
                                    ({convertDate(subscriptionStatus['period_end'])}) your plan will be changed to
                                    the {subscriptionStatus['next_plan']} Plan.</Text>
                                <br/>
                            </>
                            :
                            <></>
                    }
                    {
                        subscriptionStatus ?
                            <>
                                <Text size='sm'>You are currently subscribed to
                                    the {subscriptionStatus['current_plan']} plan{billingInterval()}</Text>
                                <br/>
                            </>
                            :
                            <></>
                    }

                </Center>
                <Space h="sm"></Space>

                <SimpleGrid cols={{base: 1, sm: 2, md: 3, lg: 4, xl: 5}} spacing={36}>
                    {plans.map((plan) => {
                        const monthlyPrice = isAnnual
                            ? (plan.price.monthly * 10) / 12 // Calculate the effective monthly price if paid annually
                            : plan.price.monthly;

                        return (
                            <Card
                                key={plan.name}
                                style={styles.planCard(!!plan.favorite && isAuthenticated)} // Ensure plan.favorite is a boolean
                                shadow="md"
                                withBorder
                                radius="lg"
                            >
                                {
                                    plan.favorite ?
                                        <Card.Section inheritPadding py="xs" bg={theme.colors.brand[6]}>
                                            <Group justify="center">
                                                <Text c={theme.white}>FAVORITE</Text>
                                            </Group>
                                        </Card.Section>
                                        :
                                        <Card.Section inheritPadding py="xs">
                                            <Group justify="center">
                                                <Text c={theme.white}>&nbsp;</Text>
                                            </Group>
                                        </Card.Section>
                                }
                                <Stack align="center" gap="xs" justify="flex-start">
                                    <Text style={styles.title}>
                                        {plan.name.charAt(0).toUpperCase() + plan.name.slice(1)}
                                    </Text>
                                    <Title style={styles.price}>
                                        ${monthlyPrice.toFixed(2)}
                                        <Text style={styles.priceDetails}>
                                            per User / Month
                                        </Text>
                                        {isAnnual ?
                                            <Text style={styles.priceSmallDetails}>
                                                ${(plan.price.monthly * 10).toFixed(2)} per User / Year
                                            </Text>
                                            :
                                            <></>
                                        }
                                    </Title>
                                </Stack>
                                <ul style={styles.featureList}>
                                    {plan.features
                                        .filter((feature) => feature.value !== 'n/a')
                                        .map((feature) => (
                                            <li key={feature.name} style={styles.featureItem}>
                                                <Tooltip label={getFeatureDescription(feature.name)}>
                                                    <Text>{feature.name}</Text>
                                                </Tooltip>
                                                <Tooltip label={feature.description}>
                                                    <Text style={{fontWeight: 700}}>{feature.value}</Text>
                                                </Tooltip>
                                            </li>
                                        ))}
                                </ul>
                                {
                                    isAuthenticated && subscriptionStatus
                                        ? <>
                                            <div style={styles.featureSpacer}/>
                                            <div style={styles.buttonWrapper}>
                                                <PurchaseSubButton
                                                    subscriptionContext={subscriptionStatus}
                                                    plan={plan}
                                                    isMonthly={!isAnnual}
                                                    refreshInfo={refreshInfo}
                                                    setCheckoutIsVisible={setCheckoutOpen}
                                                />
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </Card>
                        );
                    })}
                </SimpleGrid>
            </div>
        </Box>
    );
}

export default PricingOptions;
