import React, {useState} from 'react';
import {
    ActionIcon,
    Text,
    Group,
    useMantineTheme,
    Card,
    rem,
    Center,
    Stack,
    Tabs
} from '@mantine/core';
import {Dropzone} from '@mantine/dropzone';
import {IconUpload, IconTrash, IconEdit, IconPhoto, IconBox} from '@tabler/icons-react';
import apiService from "../services/ApiService";


function PDFSingleFileUploadOrAssetComponent({onValueChange, ...props}) {
    const [file, setFile] = useState(null);
    const theme = useMantineTheme();
    
    const createAsset = apiService.createAsset;

    const handleDrop = (files) => {
        console.log("Dropped Files: " + JSON.stringify(files))
        // Since it's a single file, we only take the first one
        setFile(files[0]);
        uploadFile(files[0]).then(assetId => {
            if (assetId) {
                onValueChange(assetId);
            } else {
                // TODO: Show error uploading
            }
        });
    };

    const deleteFile = () => {
        setFile(null);
        onValueChange('');
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('metadata', JSON.stringify({"Content-Type": "application/pdf"}));
        try {
            const {assetId, fileName} = await createAsset(formData)
            console.log('Upload successful');

            //setFile(null); // Clear file after upload
            return assetId
        } catch (error) {
            setFile(null)
            console.error('Error uploading file:', error);
            return null
        }
    };

    const iconStyle = {color: theme.colors.brand[6] , marginRight: 5, width: rem(18), height: rem(18)};

    return (
        <div>
            <Tabs
                inverted
                defaultValue={"file"}
                styles={{
                    tabLabel: {
                        color: theme.colors.brand[6]
                    },
                    tab: {
                        padding: "6px 18px",
                        fontSize: "0.75rem",
                        marginRight: "9px",
                        marginLeft: "9px",
                    },
                }}>
                <Tabs.Panel value={"text"} display={"none"}>
                    <Text
                        {...props}
                        onChange={(event) => onValueChange(event.target.value)}
                    />
                </Tabs.Panel>
                <Tabs.Panel value={"file"}>
                    <Text
                        size={"sm"}
                        weight={500}
                        style={{"marginTop": props.style.marginTop}}
                    >
                        {props.label}
                        <Text component="span" color="red" size="sm"> *</Text>
                    </Text>
                    <Dropzone display={file ? 'none' : 'block'}
                              onDrop={handleDrop}
                              onReject={(files) => console.log('rejected files', files)}
                              maxSize={50 * 1024 ** 2}
                              accept={{
                                  'application/pdf': []
                              }}
                              multiple={false}
                              style={{margin: 15}}
                              radius={"md"}
                    >
                        <Group position="center" spacing="xl" style={{minHeight: 32, pointerEvents: 'none'}}>
                            <IconUpload size={32} stroke={1.5} style={{color: theme.colors.blue[6]}}/>
                            <Text size="md" inline>
                                Drag a PDF file here or click to select a PDF file
                            </Text>
                        </Group>
                    </Dropzone>
                    {file && (
                        <div>
                            <Stack align="center">
                                <Card radius="lg" shadow="sm" h={100} w={100} style={{margin: 15}}>
                                    <Stack align="center">
                                        <Text>PDF</Text>
                                        <ActionIcon onClick={deleteFile}>
                                            <IconTrash size={16}/>
                                        </ActionIcon>
                                    </Stack>
                                </Card>
                            </Stack>
                            <Center>
                                <Text fw={500}>{file.name}</Text>
                            </Center>
                        </div>
                    )}
                </Tabs.Panel>
                <Tabs.Panel value={"asset"}>

                </Tabs.Panel>
                <Tabs.List justify="space-between">
                    <Tabs.Tab display={"none"} value={"text"} leftSection={<IconEdit style={iconStyle}/>}>Enter Text</Tabs.Tab>
                    <Tabs.Tab value={"file"} leftSection={<IconPhoto style={iconStyle}/>}>Upload PDF File</Tabs.Tab>
                    <Tabs.Tab disabled value={"asset"} leftSection={<IconBox style={iconStyle}/>}>Select PDF Asset</Tabs.Tab>
                </Tabs.List>
            </Tabs>
        </div>
    );
}

export default PDFSingleFileUploadOrAssetComponent;
