import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    TextInput,
    Button,
    Card,
    Title,
    SimpleGrid,
    Space,
    Fieldset,
    Text, Modal, LoadingOverlay, Box, Switch,
} from "@mantine/core";
import {useAuth} from "../AuthContext";
import ApiService from "../services/ApiService";
import apiService from "../services/ApiService";
import ModifyPaymentDetailsButton from "../components/ModifyPaymentDetailsButton";
import StripeForm from "../components/StripeForm";
import {useConfig} from "../ConfigContext";
import StripePaymentForm from "../components/StripePaymentForm";
import {useMediaQuery} from "@mantine/hooks";
// import { updateProfile } from "../services/apiService"; // Adjust the path as needed

const Billing = () => {
    const {isAuthenticated, profile, refreshProfile, profileUpdating} = useAuth();
    const {creditPackages} = useConfig()

    const [checkoutOpen, setCheckoutOpen] = useState(false);
    const [checkoutProductId, setCheckoutProductId] = useState<String>("")

    const [paymentModificationOpen, setPaymentModificationOpen] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({default_last4: '', subscription_last4: '', owed_balance: 0})
    const [paymentLoading, setPaymentLoading] = useState(true)
    const [paymentTogglesOpen, setPaymentTogglesOpen] = useState(false)
    const [changeDefaultMethod, setChangeDefaultMethod] = useState(true)
    const [changeSubscriptionMethod, setChangeSubscriptionMethod] = useState(profile?.plan !== 'Free')

    const navigate = useNavigate()

    function loadCardDetails() {
        setPaymentLoading(true)
        apiService.getPaymentInfo().then((res) => {
            setPaymentDetails(res);
            setPaymentLoading(false)
        })
    }

    const loadProfile = () => {
        refreshProfile(1)
    }

    const handleUpgradePlan = () => {
        navigate("/pricing");
    };

    const getPlanDetails = () => {
        return {
            description: "",
            alias: profile && "plan" in profile ? profile.plan : "Unknown"
        }
    }

    const [checkoutStatus, setCheckoutStatus] = useState(null);
    const [checkoutCustomerEmail, setCheckoutCustomerEmail] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        loadProfile()

        loadCardDetails()

        if (sessionId !== null) {

            ApiService.getCheckoutStatus(sessionId)
                .then((data) => {
                    setCheckoutStatus(data.status);
                    setCheckoutCustomerEmail(data.customer_email);
                });
        }
    }, []);

    function checkoutProduct(product_id: String) {
        setCheckoutProductId(product_id);
        setCheckoutOpen(true);
    }

    const reduceCardDetailsNavReduced = !useMediaQuery('(max-width: 765px)');
    const reduceCardDetailsNavExtended = useMediaQuery('(max-width: 780px)') && reduceCardDetailsNavReduced;
    const reduceCardDetails = useMediaQuery('(max-width: 480px)') || reduceCardDetailsNavExtended;
    const isSmallDevice = useMediaQuery('(max-width: 1450px)');

    if (!isAuthenticated) {
        return <></>
    }

    return (
        <>
            <Modal opened={checkoutStatus !== null} onClose={() => {
                setCheckoutStatus(null)
            }}>
                {
                    checkoutStatus === 'complete' ?
                        <div>Thank you for your purchase {checkoutCustomerEmail}</div>
                        :
                        <div>Hello world</div>
                }
            </Modal>
            <Modal size="75%" title="Checkout"
                opened={checkoutOpen} onClose={() => {
                setCheckoutOpen(false);
                setCheckoutProductId("")
            }}>
                <StripeForm id={checkoutProductId} checkoutCompleted={() => {
                    setTimeout(() => {
                        setCheckoutOpen(false);
                    }, 3000)
                }}/>
            </Modal>
            <Modal size="75%" title="Modify Payment Details"
                opened={paymentModificationOpen} onClose={() => {
                setPaymentModificationOpen(false);
            }}>
                {
                    paymentTogglesOpen && profile?.plan !== 'Free' ?
                        <>
                            <Switch
                                checked={changeDefaultMethod}
                                onChange={(event) => {
                                    setChangeDefaultMethod(event.currentTarget.checked)
                                }}
                                label="Modify Default Payment Method"
                            />
                            {
                                profile?.plan !== 'Free' ?
                                    <Switch
                                        checked={changeSubscriptionMethod}
                                        onChange={(event) => {
                                            setChangeSubscriptionMethod(event.currentTarget.checked)
                                        }}
                                        label="Modify Subscription Payment Method"
                                        style={{marginTop: '10px'}}
                                    />
                                    :
                                    <></>
                            }
                            <br/>
                            <Button
                                disabled={!changeSubscriptionMethod && !changeDefaultMethod}
                                onClick={() => {
                                    setPaymentModificationOpen(true)
                                    setPaymentTogglesOpen(false)
                                }}
                            >
                                Modify Payment Details
                            </Button>
                        </>
                        :
                        <StripePaymentForm
                            changeDefaultMethod={changeDefaultMethod}
                            changeSubscriptionMethod={changeSubscriptionMethod}
                            checkoutCompleted={() => {
                                setPaymentLoading(true)
                                setTimeout(() => {
                                    loadCardDetails()
                                }, 2000)
                                setTimeout(() => {
                                    setPaymentModificationOpen(false);
                                }, 4000)
                            }}
                        />
                }
            </Modal>
            <Card
                shadow="sm"
                padding="lg"
                style={{
                    margin: "auto",
                    width: isSmallDevice ? "90%" : "1000px",
                    maxWidth: "1200px",
                }}
            >
                <LoadingOverlay visible={(profileUpdating || paymentLoading) && !checkoutOpen && !paymentModificationOpen} zIndex={1000} overlayProps={{radius: "sm", blur: 2}}/>
                <SimpleGrid cols={1} spacing="lg" style={{alignItems: "flex-start"}}>
                    <Fieldset
                        variant="filled"
                        radius="md"
                        style={{height: "100%"}}
                    >
                        <Space h="xl"/>

                        <Title order={2}>
                            Billing Information
                        </Title>
                        <Space h="md"/>
                        <TextInput
                            label="Current Subscription"
                            variant="filled"
                            description={getPlanDetails().description}
                            value={getPlanDetails().alias}
                            readOnly
                        />
                        <Space h="xl"/>
                        <Button onClick={handleUpgradePlan} variant="outline">
                            Change
                        </Button>
                        <Space h="xl"/>
                        <Space h="xl"/>

                        <TextInput
                            label="Current Account Balance"
                            variant="filled"
                            value={
                                profile?.accountBalance
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " credits"
                            }
                            readOnly
                        />
                        <Space h="xl"/>
                        <Text size="sm" fw={500}>Top up your Credits</Text>
                        {
                            creditPackages.map((creditPackage) => {
                                return (
                                    <Button
                                        disabled={true}
                                        variant="outline"
                                        style={{marginTop: '10px', marginRight: '10px'}}
                                        onClick={() => {
                                            checkoutProduct(creditPackage.productId)
                                        }
                                    }>
                                        {creditPackage.credits} Credits
                                    </Button>
                                )
                            })
                        }
                        <br/>
                        <Space h="xl"/>
                        {
                            <>
                                <Text size="sm" fw={500}>Payment Details</Text>
                                {
                                    paymentDetails.owed_balance > 0 ?
                                        <Text size={'md'} style={{marginTop: reduceCardDetails ? '3px' : '0px'}}>
                                            Balance: ${(paymentDetails.owed_balance / 100).toFixed(2)}
                                        </Text>
                                        :
                                        <></>
                                }
                                {
                                    paymentDetails.default_last4 ?
                                        reduceCardDetails ?
                                            <>
                                                <Text size={'md'} style={{marginTop: '5px'}}>Default Method:</Text>
                                                <Text size={'md'}> #### #### #### {paymentDetails.default_last4}</Text>
                                            </>
                                            :
                                            <Text size={'md'}>Default Method: #### #### #### {paymentDetails.default_last4}</Text>
                                        :
                                        <Text size={'md'}>No default payment found</Text>
                                }
                                {
                                    profile === null || profile?.plan !== 'Free' ?
                                        paymentDetails.subscription_last4 ?
                                            reduceCardDetails ?
                                                <>
                                                    <Text size={'md'} style={{marginTop: '5px'}}>Subscription Method:</Text>
                                                    <Text size={'md'}> #### ####
                                                        #### {paymentDetails.subscription_last4}</Text>
                                                </>
                                                :
                                                <Text size={'md'}>Subscription Method: #### ####
                                                    #### {paymentDetails.subscription_last4}</Text>
                                            :
                                            <Text size={'md'}>No subscription payment details found</Text>
                                        :
                                        <></>
                                }
                                <br/>
                                <Button variant="outline"
                                    onClick={() => {
                                        setPaymentModificationOpen(true)
                                        setPaymentTogglesOpen(true)
                                    }}
                                >
                                    Modify Payment Details
                                </Button>
                            </>
                        }
                    </Fieldset>
                </SimpleGrid>
            </Card>
        </>
    );
};

export default Billing;
