export async function _pq(apiUrl, inputDict, taskId, appId, pullDuration, completion, statusUpdate, showHistory = false, maxAttempts = 100) {
    const parameters = {...inputDict};

    // Execute the task and then check its status
    await executeTask(
        apiUrl,
        appId,
        taskId,
        parameters,
        {name: "EMPTY", content: ""},
        (success, requestId) => {
            if (success) {
                checkTaskStatus(
                    apiUrl,
                    appId,
                    requestId,
                    pullDuration,
                    maxAttempts,
                    (statusSuccess, status, results, missing=null) => {
                        if (statusSuccess) {
                            if (missing) {
                                completion(results, null, false, missing)
                            } else {
                                completion(results, null, false); // success callback
                            }
                        } else {
                            completion(null, "Error in processing the task: " + JSON.stringify(results), true); // error callback
                        }
                    },
                    statusUpdate
                );
            } else {
                completion(null, "Failed to execute the task: " + requestId, true); // error callback
            }
        },
        showHistory
    );
}

//export async function _pq_resume(apiUrl, inputDict, taskId, appId, pullDuration, completion, statusUpdate, showHistory = false, maxAttempts = 100) {
export async function _pq_resume(apiUrl, parameters, requestId, appId, pullDuration, completion, statusUpdate, showHistory = false, maxAttempts = 100) {
    console.info("\n\nResuming request: " + requestId)
    console.info("\tmissing parameters: " + JSON.stringify(parameters))
    await resumeTask(
        apiUrl,
        appId,
        requestId,
        parameters,
        {name: "EMPTY", content: ""},
        (success, requestId) => {
            if (success) {
                checkTaskStatus(
                    apiUrl,
                    appId,
                    requestId,
                    pullDuration,
                    maxAttempts,
                    (statusSuccess, status, results, missing=null) => {
                        if (statusSuccess) {
                            if (missing) {
                                console.info("\n\n\nPaused state: \n\t\tresults: \n\t\t" + JSON.stringify(results) + " \n\n\n")
                                console.info("\n\n\nPaused state: \n\t\tmissing: \n\t\t" + JSON.stringify(missing) + " \n\n\n")
                                completion(results, null, false, missing)
                            } else {
                                console.info("\n\n\nComplete state: \n\t\tresults: \n\t\t" + JSON.stringify(results) + " \n\n\n")
                                completion(results, null, false); // success callback
                            }
                        } else {
                            completion(null, "Error in processing the task: " + JSON.stringify(results), true); // error callback
                        }
                    },
                    statusUpdate
                );
            } else {
                completion(null, "Failed to execute the task: " + requestId, true); // error callback
            }
        },
        showHistory
    );
}

async function executeTask(apiUrl, appId, taskId, parameterList, character, completion, showHistory = false) {
    const url = `${apiUrl}/api/execute/task?_=${new Date().getTime()}`;

    const requestBody = {
        appId: appId,
        taskId: taskId,
        parameters: parameterList,
        character: character
    };

    if (showHistory) {
        requestBody['showHistory'] = true
    }

    try {
        const requestOptions = {
            method: "POST",
            headers: {
                'Cache-Control': 'no-cache',
                "Content-Type": "application/json"
            },
            mode: "cors",
            body: JSON.stringify(requestBody)
        }

        if (appId) {
            requestOptions.headers["API-Key"] = appId
        } else {
            requestOptions.headers["Authorization"] = `Bearer ${localStorage.getItem("sessionToken")}`
        }

        const response = await fetch(url, requestOptions);

        if (response.ok) {
            const jsonResponse = await response.json();
            const parsedBody = JSON.parse(jsonResponse.body)

            if (parsedBody.status === 'IN_PROGRESS') {
                const requestId = parsedBody.requestId;
                completion(true, requestId);
            } else {
                completion(false, 'Request Parsing Error: ' + JSON.stringify(parsedBody.status));
            }
        } else {
            console.error(`Network response was not ok (${response.status})`);
            completion(false, `Network response was not ok (${response.status})`);
        }
    } catch (error) {
        console.error('Error:', error);
        completion(false, "Error with Response Processing: " + error);
    }
}

async function resumeTask(apiUrl, appId, requestId, parameterList, character, completion, showHistory = false) {
    const url = `${apiUrl}/api/request/resume/${requestId}?_=${new Date().getTime()}`;

    const requestBody = {
        parameters: parameterList,
    };

    if (showHistory) {
        requestBody['showHistory'] = true
    }

    try {
        const requestOptions = {
            method: "POST",
            headers: {
                'Cache-Control': 'no-cache',
                "Content-Type": "application/json"
            },
            mode: "cors",
            body: JSON.stringify(requestBody)
        }

        if (appId) {
            requestOptions.headers["API-Key"] = appId
        } else {
            requestOptions.headers["Authorization"] = `Bearer ${localStorage.getItem("sessionToken")}`
        }

        const response = await fetch(url, requestOptions);

        if (response.ok) {
            const jsonResponse = await response.json();
            const parsedBody = JSON.parse(jsonResponse.body)

            if (parsedBody.status === 'IN_PROGRESS') {
                const requestId = parsedBody.requestId;
                completion(true, requestId);
            } else {
                completion(false, 'Request Parsing Error: ' + JSON.stringify(parsedBody.status));
            }
        } else {
            console.error(`Network response was not ok (${response.status})`);
            completion(false, `Network response was not ok (${response.status})`);
        }
    } catch (error) {
        console.error('Error:', error);
        completion(false, "Error with Response Processing: " + error);
    }
}

async function checkTaskStatus(apiUrl, appId, requestId, interval, maxAttempts, completion, statusUpdate) {
    const statusUrl =
        `${apiUrl}/api/request/results/${requestId}?_=${new Date().getTime()}`;
    let attempts = 0;
    let intervalId; // Declare intervalId at this level

    const callForTaskStatus = async () => {
        try {
            const requestOptions = {
                method: "GET", // Specifying the use of the GET method
                headers: {
                    'Cache-Control': 'no-cache',
                    Accept: "application/json"
                }
            };

            if (appId) {
                requestOptions.headers["API-Key"] = appId
            } else {
                requestOptions.headers["Authorization"] = `Bearer ${localStorage.getItem("sessionToken")}`
            }

            const response = await fetch(statusUrl, requestOptions);
            if (response.ok) {
                const responseBody = await response.json();
                const body = JSON.parse(responseBody.body)
                statusUpdate(body)
                if (body.status === "COMPLETED") {
                    clearInterval(intervalId);
                    completion(true, body.status, body.results);
                } else if (body.status === "IN_PROGRESS") {
                } else if (body.status === "PAUSED") {
                    clearInterval(intervalId);
                    completion(true, body.status, body.results, body.missing)
                } else if (body.status === "ERROR") {
                    console.error(
                        "Task resulted in an Error. Status:",
                        body.status
                    );
                    if (body.step >= 0) {
                        clearInterval(intervalId);
                        completion(false, body.status, null);
                    }
                } else {
                    console.error("Unexpected task status:", body.status, body);
                    clearInterval(intervalId);
                    completion(false, null);
                }
            } else {
                console.error("Failed to check task status:", await response.json());
                clearInterval(intervalId);
                completion(false, null);
            }
        } catch (error) {
            console.error("Error while checking task status:", error);
            clearInterval(intervalId);
            completion(false, null);
        }

        if (++attempts >= maxAttempts) {
            console.error("Maximum pull attempts reached.");
            clearInterval(intervalId);
            completion(false, null);
        }
    }

    await callForTaskStatus()
    intervalId = setInterval(callForTaskStatus, interval);
}
