import '@mantine/core/styles.css';
import '@mantine/core/styles.layer.css';
import React, {useState} from "react";
import {
    Title,
    Text,
    Group, List, ThemeIcon, Image, rem, Button, useMantineTheme, Modal, Grid, Center,
} from "@mantine/core";
import {useConfig} from "../ConfigContext";
import classes from './Home.module.css';
import {IconCheck} from "@tabler/icons-react";
import {useAuth} from "../AuthContext";
import Login from "../components/Login";
import {useMediaQuery} from "@mantine/hooks";

function Home() {
    const theme = useMantineTheme();
    const {home} = useConfig();
    const {isAuthenticated, profile} = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const layoutVertical = useMediaQuery('(max-width: 600px)');

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const signupButtons =   <Group mt={30}>
                                {!isAuthenticated && (
                                    <Button radius="xl" size="md" className={classes.control} onClick={handleOpenModal}>
                                        Sign Up
                                    </Button>
                                )}
                            </Group>

    const introText = <>
                        {
                        home.body.split("|").map((body) => (
                            body[0] === "#"?
                            <Text fw={900}  mt="md">
                                {body.replace(/^#/, '')}
                            </Text>
                                :
                            <Text  mt="md">
                                {body}
                            </Text>
                        ))
                        }
                    </>

    const featureList = <>
                        <List
                        mt={30}
                        spacing="lg"
                        size="md"
                        >
                            {home.features.map((feature) => (
                                <List.Item
                                    key={feature.title}
                                    icon={
                                        <ThemeIcon color={"#ff000000"} size={44} radius="xl">
                                            {feature.icon
                                                ? React.createElement(feature.icon, {
                                                    stroke: 1.5,
                                                    color: theme.colors.brand[6],
                                                    width: rem(16),
                                                    height: rem(16)
                                                    // width: rem(26)
                                                })
                                                : null}
                                        </ThemeIcon>
                                    }
                                >
                                    <Text fw={600} c={theme.colors.brand[6]} size={rem("22px")} >{feature.title}</Text>
                                    <Text fw={300}>{feature.description}</Text>
                                </List.Item>
                            ))}
                        </List>
                        {signupButtons}
                        </>

    return (
        <>
            <Grid gutter="lg" className={classes.inner} columns={layoutVertical ? 6 : 10}>
                <Grid.Col span={layoutVertical ? 6 : 10}>
                    <Title className={classes.title}>
                        {home.title}
                    </Title>
                </Grid.Col>

                <Grid.Col span={4}>
                    <Center>
                        <Image
                            src={home.image}
                            className={classes.image}
                            radius={"15px"}
                        />
                    </Center>
                    {layoutVertical ? introText : featureList}
                </Grid.Col>
                <Grid.Col span={6}>
                    {layoutVertical ? featureList : introText}
                </Grid.Col>


            </Grid>
            <Modal
                opened={isModalOpen}
                onClose={handleCloseModal}
                title="Sign Up"
                centered
            >
                <Login onClose={handleCloseModal} initialSignup={true}/>
            </Modal>
        </>
    );
}

export default Home;

