import React from 'react';
import {Text, Grid, SimpleGrid, Card, useMantineTheme, Image, Center, Stack, List, Spoiler} from '@mantine/core';
import {useConfig} from '../ConfigContext';

function Team() {
    const {team} = useConfig();
    const theme = useMantineTheme();

    const styles = {
        wrapper: {
            padding: `calc(var(--mantine-spacing-sm) * 2) var(--mantine-spacing-sm)`,
        },
        person: {
            tile: {
                background: theme.colors.accent[0] + "30",
                shadow: '50px',
                padding: '25px',
            },
            photo: {
                color: theme.colors.brand[4],
                width: '256px',
                height: '256px',
                margin: 18,
                '@media (max-width: 768px)': {
                    width: '75%',
                    height: '75%',
                    maxWidth: '192px',
                    maxHeight: '192px',
                },
                '@media (max-width: 480px)': {
                    width: '50%',
                    height: '50%',
                    maxWidth: '128px',
                    maxHeight: '128px',
                },
            },
            name: {
                fontFamily: `Poppins, var(--mantine-font-family)`,
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: 0.8,
            },
            title: {
                fontFamily: `Poppins, var(--mantine-font-family)`,
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: 1.3,
                marginBottom: 'var(--mantine-spacing-md)',
                color: theme.colors.brand[6],
            },
            description: {
                fontFamily: `Greycliff CF, var(--mantine-font-family)`,
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: 1.3,
                marginBottom: 'var(--mantine-spacing-md)',
            }
        }
    };

    const items = team.map((person) => (
        <Card shadow={"md"} withBorder key={person.name} radius={"lg"} style={styles.person.tile}>
            <Card.Section>
                <Center>
                    <Stack>
                        <Image src={person.photo} style={styles.person.photo} w="auto" fit="contain" radius={128}/>
                        <Text ta="center" style={styles.person.name}>
                            {person.name}
                        </Text>
                        <Text ta="center" style={styles.person.title}>
                            {person.title}
                        </Text>
                    </Stack>
                </Center>
            </Card.Section>
            <Text style={styles.person.description}>
                {person.intro}
            </Text>
            <Spoiler hideLabel={"less"} showLabel={"more"} maxHeight={0}>
                <Card.Section>
                    <List
                        spacing={"xs"}
                        size={"sm"}
                        style={{padding: 25}}
                    >
                        {person.experience?.map((exp, idx) =>
                            <List.Item key={idx}>
                                <Text fw={700}>
                                    {exp.split('|')[0]}
                                </Text>
                                <Text fw={300}>
                                    {exp.split('|')[1]}
                                </Text>
                            </List.Item>)}
                    </List>
                </Card.Section>
            </Spoiler>
        </Card>
    ));

    return (
        <div style={styles.wrapper}>
            <Grid gutter={80}>
                <Grid.Col span={{base: 12}}>
                    <SimpleGrid cols={{base: 1, sm: 2, md: 3, lg: 4}} spacing={36}>
                        {items}
                    </SimpleGrid>
                </Grid.Col>
            </Grid>
        </div>
    );
}

export default Team;
