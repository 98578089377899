import {Text, Box, Stack, rem, useMantineTheme, ThemeIcon} from '@mantine/core';
import classes from './ContactIcons.module.css';
import {useConfig} from "../ConfigContext";
import React from "react";
import {IconCheck} from "@tabler/icons-react";

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    icon: React.ElementType;
    title: React.ReactNode;
    description: React.ReactNode;
}

function ContactIcon({icon, title, description, ...others}: ContactIconProps) {
    const theme = useMantineTheme();

    return (
        <div className={classes.wrapper} {...others}>
            {
                <ThemeIcon size={36} radius="xl" style={{marginRight:"15px", padding: "5px"}}>
                    {React.createElement(icon, {
                        color: theme.colors.accent[0],
                    })}
                </ThemeIcon>
            }

            <div>
                <Text size="xs" className={classes.title}>
                    {title}
                </Text>
                <Text className={classes.description}>{description}</Text>
            </div>
        </div>
    );
}

export function ContactIconsList() {
    const {contacts} = useConfig();

    const items = contacts.map((item, index) => <ContactIcon key={index} {...item} />);
    return <Stack>{items}</Stack>;
}
