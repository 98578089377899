// BasicModal.js
import React from "react";
import { Modal } from "@mantine/core";

const BasicModal = ({ opened, onClose, children, title, size = "70%" }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false} // Disables closing the modal by clicking the overlay
      closeOnEscape={false} // Disables closing the modal by pressing the Escape key
      title={title}
      radius="lg"
      centered
      size={size}
      overlayProps={{
        backgroundOpacity: 0.5,
        blur: 3,
      }}
    >
      {children}
    </Modal>
  );
};

export default BasicModal;
