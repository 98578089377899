import React from 'react';
import {
    Text,
    Grid,
    SimpleGrid,
    Card,
    useMantineTheme,
    Stack,
    ThemeIcon, rem
} from '@mantine/core';
import { useConfig } from '../ConfigContext';

function Consulting() {
    const { consulting } = useConfig();
    const theme = useMantineTheme();

    const styles = {
        wrapper: {
            padding: `calc(var(--mantine-spacing-sm) * 2) var(--mantine-spacing-sm)`,
        },
        offering: {
            card: {
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: theme.shadows.xl,
                },
                padding: 'var(--mantine-spacing-md)',
                borderRadius: 'var(--mantine-radius-lg)',
                backgroundColor: theme.colors.accent[0] + "30",
            },
            icon: {
                marginBottom: 'var(--mantine-spacing-md)',
                width: rem(48),
                height: rem(48),
            },
            title: {
                fontFamily: `Poppins, var(--mantine-font-family)`,
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: 1.3,
                marginBottom: 'var(--mantine-spacing-sm)',
                color: theme.colors.brand[6],
                textAlign: 'center' as 'center',
            },
            description: {
                fontFamily: `Greycliff CF, var(--mantine-font-family)`,
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: 1.5, // Increased line-height for better readability
                color: theme.colors.gray[7],
                textAlign: 'left' as 'left',
                marginBottom: 'var(--mantine-spacing-md)', // Added margin for spacing
            },
            value: {
                fontFamily: `Greycliff CF, var(--mantine-font-family)`,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: 1.3,
                color: theme.colors.gray[7],
                textAlign: 'left' as 'left',
                marginTop: 'var(--mantine-spacing-md)', // Added margin for spacing
            },
            valueList: {
                paddingLeft: '20px',
                margin: 5,
            },
            valueItem: {
                marginBottom: 'var(--mantine-spacing-xs)',
            }
        }
    };

    const items = consulting.map((offering) => (
        <Card
            key={offering.title}
            style={styles.offering.card}
            shadow="md"
            withBorder
            radius="lg"
        >
            <Stack>
                <ThemeIcon color="transparent" size={56} radius="xl" style={styles.offering.icon}>
                    {offering.icon
                        ? React.createElement(offering.icon, {
                            stroke: 1.5,
                            color: theme.colors.brand[6],
                            width: rem(48),
                            height: rem(48)
                        })
                        : null}
                </ThemeIcon>
                <Text style={styles.offering.title}>
                    {offering.title}
                </Text>
            </Stack>
            <Text style={styles.offering.value}>
                Business Value:
                <ul style={styles.offering.valueList}>
                    {offering.valuePoints.map((point, index) => (
                        <li key={index} style={styles.offering.valueItem}>{point}</li>
                    ))}
                </ul>
            </Text>
            <Text style={styles.offering.description}>
                {offering.description}
            </Text>
        </Card>
    ));

    return (
        <div style={styles.wrapper}>
            <Grid gutter={80}>
                <Grid.Col span={{ base: 12 }}>
                    <SimpleGrid cols={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={36}>
                        {items}
                    </SimpleGrid>
                </Grid.Col>
            </Grid>
        </div>
    );
}

export default Consulting;
