// ResultDisplay.js
import React from 'react';
import {Paper, Text, Image, Divider, Group, Space} from "@mantine/core";
import AudioPlayer from "./AudioPlayer";
import LoadAsset from "./LoadAsset";
import TextCopyButton from "./TextCopyButton";
import DownloadButton from "./DownloadButton";
import FeedbackComponent from "./FeedbackComponent";

const ResultDisplay = ({result, generateAssetUrl, setContentForClipboard, contentForClipboard}) => {
    const contentType = result.parameter.metadata['Content-Type'].split('/')[0];
    return result.parameter.value.map((item, index) => {
        switch (contentType) {
            case 'image':
                return (
                    <>
                        <Paper key={index} shadow="xs" radius="md" withBorder p="xl"
                               style={{marginBottom: 25, paddingBottom: 10}}>
                            <Group justify="space-between">
                                <Text size="lg" c='#7DA1E8'>{result.parameter.name}</Text>
                                <DownloadButton
                                    url={generateAssetUrl(item.data)}
                                    fileName={result.parameter.name}
                                    label="Download Image"
                                />
                            </Group>
                            <Image src={generateAssetUrl(item.data)}/>
                            <Space  h="md"/>
                            <FeedbackComponent item={item}/>
                        </Paper>
                    </>
                );

            case 'audio':
                return (
                    <div key={index}>
                        <Group justify="space-between">
                            <Text size="lg" c='#7DA1E8'>{result.parameter.name}</Text>
                            <DownloadButton
                                url={generateAssetUrl(item.data)}
                                fileName={result.parameter.name}
                                label="Download Audio"
                            />
                        </Group>
                        <Paper shadow="xs" radius="md" withBorder p="xl" style={{marginBottom: 5, paddingBottom: 5}}>
                            <AudioPlayer src={generateAssetUrl(item.data)}/>
                        </Paper>
                        <FeedbackComponent item={item}/>
                    </div>
                );

            default:
                return (
                    <div key={index}>
                        <Group justify="space-between">
                            <Text size="lg" c='#7DA1E8'>{result.parameter.name}</Text>
                            <TextCopyButton text={contentForClipboard[item.data]}/>
                        </Group>
                        <Divider my="sm"/>
                        <LoadAsset style={{paddingTop: 5}}
                                   src={generateAssetUrl(item.data)}
                                   assetId={item.data}
                                   onContentLoad={(assetId, content) => {
                                       setContentForClipboard(currentContent => ({
                                           ...currentContent,
                                           [assetId]: content
                                       }));
                                   }}
                        />
                        <FeedbackComponent item={item}/>
                    </div>
                );
        }

    })
};

export default ResultDisplay;
