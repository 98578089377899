import {Route, Routes} from 'react-router-dom';
import {} from "@mantine/core";
import Home from "../pages/Home";
import Team from "../pages/Team";
import News from "../pages/News";
import Demos from "../pages/Demos";
import {FAQ} from "../pages/FAQ";
import {ContactUs} from "../pages/ContactUs";
import Consulting from "../pages/Consulting";
import Profile from "../pages/Profile";
import PricingOptions from "../pages/PricingOptions";
import Billing from "../pages/Billing";
import TasksPage from "../pages/Tasks";
import Features from "../pages/Features";
import LinkedInAuthPopup from "../pages/LinnkedInAuthPopup";

const RouteSwitcher = () => {
    return (
        <Routes>
            <Route path="*" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/our-team" element={<Team/>}/>
            <Route path="/news" element={<News/>}/>
            <Route path="/news/:assetId" element={<News/>}/>
            <Route path="/demos/:assetId" element={<Demos/>}/>
            <Route path="/demos" element={<Demos/>}/>
            <Route path="/faq" element={<FAQ/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
            <Route path="/intelligence-consulting" element={<Consulting/>}/>
            <Route path="/feature-and-benefits" element={<Features/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/pricing" element={<PricingOptions/>}/>
            <Route path="/billing" element={<Billing/>}/>
            <Route path="/tasks" element={<TasksPage/>}/>
            <Route path="/linkedin-auth" element={<LinkedInAuthPopup/>}/>
        </Routes>
    );
};

export default RouteSwitcher;
