import React, { useState, useEffect } from 'react';
import { Loader } from '@mantine/core';
import Markdown from 'react-markdown'

const LoadAsset = ({ src, assetId, onContentLoad }) => {
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);
    //console.log("SRC: " + src)
    useEffect(() => {
        setLoading(true); // Start loading
        fetch(src)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text(); // or .json() if you're fetching JSON data
            })
            .then((data) => {
                //console.log("Data: " + data)
                setContent(data);
                if (onContentLoad) {
                    onContentLoad(assetId, data);  // Call the callback with the loaded data
                }
            })
            .catch((error) => {
                console.error("Error fetching content:", error);
                setContent('Error loading content');
            })
            .finally(() => setLoading(false)); // Stop loading regardless of outcome
    }, [src]); // Re-fetch if src changes

    if (loading) {
        return <Loader />; // Show loader while content is loading
    }

    // Assuming the content is safe to directly inject, use dangerouslySetInnerHTML
    // Consider using a sanitization library if the content comes from an untrusted source
    // return <Markdown><div dangerouslySetInnerHTML={{ __html: content }} /></Markdown>;
    return <>
        <Markdown children={content}/></>
};

export default LoadAsset;
