import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
    Text,
    Grid,
    SimpleGrid,
    Card,
    Image,
    Group,
    Container,
    Badge,
    TextInput,
    List, AspectRatio, Spoiler
} from '@mantine/core';
import {useConfig, DemosItem} from '../ConfigContext';
import classes from './Demos.module.css';

function Demos() {
    const {demosCategories, demos, authors} = useConfig();
    const authorLookup = Object.fromEntries(authors.map(author => [author.name, author]));
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredDemos, setFilteredDemos] = useState(demos);
    const [selectedDemosItem, setSelectedDemosItem] = useState<DemosItem | null>(null);
    const {assetId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (assetId) {
            const demosItem = demos.find(item => item.assetId === assetId);
            if (demosItem) {
                setSelectedDemosItem(demosItem);
            } else {
                navigate('/demos');
            }
        } else {
            setSelectedDemosItem(null);
        }
    }, [assetId, demos, navigate]);

    useEffect(() => {
        const lowercaseFilter = searchQuery.toLowerCase();
        const filteredBySearch = demos.filter(item =>
            item.title.toLowerCase().includes(lowercaseFilter) ||
            item.abstract?.toLowerCase().includes(lowercaseFilter) ||
            (item.author && item.author.toLowerCase().includes(lowercaseFilter)) ||
            item.categoryId.toLowerCase().includes(lowercaseFilter)
        );

        const filteredByCategory = selectedCategory
            ? filteredBySearch.filter(item => item.categoryId === selectedCategory)
            : filteredBySearch;

        setFilteredDemos(filteredByCategory);
    }, [searchQuery, selectedCategory, demos]);

    const handleSelectDemosItem = (item: DemosItem) => {
        navigate(`/demos/${item.assetId}`);
    };

    const renderDemosItem = (item: DemosItem) => (
        <Card withBorder radius="md" className={classes.card} key={item.assetId}>
            <Card.Section style={{padding: "15px"}}>
                <AspectRatio ratio={16 / 9} mx="auto">
                    <iframe
                        src={item.image}
                        title="YouTube video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </AspectRatio>
            </Card.Section>
            <Text className={classes.title} fw={500} component="a" style={{marginTop: 10}}>
                {item.title}
            </Text>
            <Text fz="sm" c="dimmed">
                <Spoiler hideLabel={"less"} showLabel={"more"} maxHeight={0}>
                    {item.abstract}
                </Spoiler>
            </Text>
        </Card>
    );


    return (
        <Container py="xl" size="xl">
            <TextInput
                radius={"xl"}
                placeholder="Search articles by name, author, or category"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.currentTarget.value)}
                style={{marginBottom: '20px'}}
            />
            <Group style={{justifyContent: 'center', margin: '10px 0'}}>
                {Object.keys(demosCategories).map((category) => (
                    <Badge
                        key={category}
                        color={selectedCategory === category ? 'blue' : 'gray'}
                        onClick={() => setSelectedCategory(category)}
                        style={{cursor: 'pointer'}}
                    >
                        {demosCategories[category].title}
                    </Badge>
                ))}
                <Badge color="red" onClick={() => setSelectedCategory('')} style={{cursor: 'pointer'}}>
                    Clear Filter
                </Badge>
            </Group>
            <Grid>
                <Grid.Col span={12}>
                    <SimpleGrid cols={3}>
                        {filteredDemos.map(renderDemosItem)}
                    </SimpleGrid>
                </Grid.Col>
            </Grid>
        </Container>
    );
}

export default Demos;
