// CategoryTasks.js
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Card, Flex, Image, Text, Badge, Button, ScrollArea, Modal, Group, useMantineTheme} from '@mantine/core';
// import UseTaskModal from "./UseTaskModal";
import {useAuth} from "../AuthContext";
import SampleTaskModal from "./SampleTaskModal";
import UseTaskModal from "./UseTaskModal";

const CategoryTasks = ({tasks, showPowerFee = true, appId, showHistory = false}) => {
    const theme = useMantineTheme();
    
    const cardStyle = {
        height: "256px", // Fixed height for all cards
        width: "384px", // Fixed width for all cards
        //backgroundColor: "#fff",
        //border: "2px solid transparent",
    };
    const [modalUseTaskOpened, setModalUseTaskOpened] = useState(false);
    const [modalSampleTaskOpened, setModalSampleTaskOpened] = useState(false);
    const [task, setTask] = useState({});
    const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);
    const [upgradeTask, setUpgradeTask] = useState({});
    const {profile} = useAuth()

    //TODO Remove hardcoding
    const planHierarchy = {
        free: 0,
        discovery: 3,
        explorer: 9,
        creator: 18,
        enterprise: 36,
    };

    function isEligible(task) {
        // Retrieve the user's current plan
        const userPlan = profile.plan.toLowerCase();

        // Retrieve the required plan for the task
        const requiredPlans = task.plans.toLowerCase().split("|");

        return requiredPlans.some(
            (plan) => planHierarchy[userPlan] >= planHierarchy[plan]
        );
    }

    function openUpgradeModalWithTask(task) {
        setUpgradeTask(task);
        setUpgradeModalOpened(true);
    }

    function openModalWithUseTask(task) {
        setModalUseTaskOpened(true);
        setTask(task);
    }

    function openModalWithSampleTask(task) {
        setModalSampleTaskOpened(true);
        setTask(task);
    }

    const [scrollAreaHeight, setScrollAreaHeight] = useState(0);
    const containerRef = useRef(null);

    // Dynamically calculate the height of the scroll area
    const updateScrollAreaHeight = () => {
        if (containerRef.current) {
            const containerTop = containerRef.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            const calculatedHeight = windowHeight - containerTop - 20; // Subtract container's top position and an additional margin
            setScrollAreaHeight(calculatedHeight);
        }
    };

    useEffect(() => {
        // Calculate the initial height
        updateScrollAreaHeight();

        // Add event listener for window resize
        window.addEventListener('resize', updateScrollAreaHeight);

        // Clean up event listener
        return () => window.removeEventListener('resize', updateScrollAreaHeight);
    }, []); // Empty dependency array ensures this effect runs only on mount and unmount


    return (

        <div ref={containerRef} style={{height: '100%', overflow: 'auto'}}> {/* This div is the parent container */}

            <ScrollArea style={{ height: `${scrollAreaHeight - 50}px`, overflowY: 'auto' }}>
                <Flex
                    mih={50}
                    gap="xl"
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap"
                >
                    {tasks && tasks.map((task, index) => (
                        <Card
                            radius="lg"
                            key={index}
                            // onMouseOver={(e) =>
                            //     (e.currentTarget.style.border =
                            //         "2px solid " + theme.colors.brand[6])
                            // }
                            // onMouseOut={(e) =>
                            //     (e.currentTarget.style.border = "none")
                            // }
                            shadow="sm"
                            padding="lg"
                            style={cardStyle}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "start",
                                    gap: "10px",
                                }}
                            >
                                <Image
                                    src={task.imageName}
                                    radius="lg"
                                    alt="Icon"
                                    fit="scale-down"
                                    h={96}
                                    w="auto"
                                />
                                <div>
                                    <Text fw={700} c={theme.colors.brand[6]}>
                                        {task.name}
                                    </Text>
                                    <Text fw={700} size="xs">
                                        {task.heading}
                                    </Text>
                                    {showPowerFee ?
                                        <Badge color="green" variant="light">
                                            {task.powerFee} credits
                                        </Badge> : <></>}
                                </div>
                            </div>
                            <ScrollArea scrollHideDelay={3500}>
                                <Text size="xs" style={{marginTop: "10px"}}>
                                    {task.description}
                                </Text>
                            </ScrollArea>
                            <Group justify="space-between" grow
                                   style={{
                                       display: "flex",
                                       marginTop: "auto",
                                   }}
                            >
                                {isEligible(task) ? (
                                    <Button
                                        variant="filled"
                                        size="sm"
                                        onClick={() => openModalWithUseTask(task)}
                                        style={{outline: "none"}}
                                    >
                                        Use
                                    </Button>
                                ) : (
                                    <Button
                                        color="orange"
                                        variant="outline"
                                        size="sm"
                                        style={{outline: "none"}}
                                        onClick={() => openUpgradeModalWithTask(task)}
                                    >
                                        Upgrade
                                    </Button>
                                )}
                                <></>
                                <Button
                                    variant="outline"
                                    disabled={false}
                                    size="sm"
                                    onClick={() => openModalWithSampleTask(task)}
                                    style={{outline: "none"}}
                                >
                                    Sample
                                </Button>
                            </Group>
                        </Card>
                    ))}
                    <UseTaskModal
                        modalOpened={modalUseTaskOpened}
                        setModalOpened={setModalUseTaskOpened}
                        task={task}
                        appId={appId}
                        showHistory={showHistory}
                    />
                    <SampleTaskModal
                        modalOpened={modalSampleTaskOpened}
                        setModalOpened={setModalSampleTaskOpened}
                        task={task}
                    />
                    <Modal
                        opened={upgradeModalOpened}
                        onClose={() => setUpgradeModalOpened(false)}
                        title="Upgrade Plan"
                        centered
                    >
                        <div style={{textAlign: "center"}}>
                            <Text align="left">
                                To access the task "{upgradeTask.name}", you need to upgrade
                                your plan. The upgrades will be available in February 2024. We
                                will notify you with an email to {profile.id}
                            </Text>
                            <Button
                                onClick={() => setUpgradeModalOpened(false)}
                                style={{marginTop: "20px", display: "inline-block"}}
                            >
                                Ok
                            </Button>
                        </div>
                    </Modal>
                </Flex>
            </ScrollArea>
        </div>
    );
};

export default CategoryTasks;
